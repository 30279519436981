import { Accordion } from '@next-components/accordion';
import { MarginAccordion } from './MarginCart.styled';
import { IMargin, IMarginInput, INextArticle, IOrder, IVehicleConfiguration, getLatestMargin } from 'next-common';
import { Button } from '@next-components/button';
import { ToggleSwitch } from '@next-components/toggle-switch';
import { cloneDeep, get } from 'lodash';
import { CalculateDiscount } from './CalculateDiscount';
import { CalculateImporterDiscount } from './CalculateImporterDiscount';
import { MarginCartDetails } from './MarginCartDetails';
import { useEffect, useState } from 'react';
import { ICartPrices } from '@next-components/cart';
import { useMutation } from '@apollo/client';
import { createAndAddMarginToOrderMutationParsed } from '../../../common';
import { checkHasMarginChanged, postAndSaveMargin } from './MarginCartUtils';
import { calculateVat } from '../../../utils';

interface IMarginCartProps {
  order: IOrder;
  vehicleConfiguration: IVehicleConfiguration;
  articles: INextArticle[];
  vatPrices: ICartPrices;
  carType: string;
  isUnavailablePimData?: boolean;
  saveAccessories?(): Promise<IOrder>;
}

export const MarginCart = (props: IMarginCartProps) => {
  const [checkedIndex, setCheckedIndex] = useState(false);
  const [latestMargin, setLatestMargin] = useState<IMargin>(getLatestMargin(get(props, 'order.margins', null)));
  const [activeMargin, setActiveMargin] = useState<IMarginInput>(getLatestMargin(get(props, 'order.margins', null)));
  const [hasMarginChanged, setHasMarginChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [vatPrices, setVatPrices] = useState<ICartPrices>(null);
  const [articles, setArticles] = useState<INextArticle[]>([]);
  const [isValidCampaignDiscount, setIsValidCampaignDiscount] = useState<boolean>(true);
  const [isValidImporterDiscount, setIsValidImporterDiscount] = useState<boolean>(true);
  const [createAndSaveMarginMutation] = useMutation(createAndAddMarginToOrderMutationParsed);

  useEffect(() => {
    setVatPrices(props.vatPrices);
    setArticles(props.articles);
  }, [props.vatPrices, props.articles]);

  useEffect(() => {
    setLatestMargin(getLatestMargin(get(props, 'order.margins', null)));
    setActiveMargin(getLatestMargin(get(props, 'order.margins', null)));
  }, [props.order?.margins]);

  useEffect(() => {
    setHasMarginChanged(checkHasMarginChanged(latestMargin, activeMargin));
  }, [activeMargin]);

  const handleSwitchToggle = (): void => {
    setCheckedIndex(!checkedIndex);
  };

  const saveMargin = async (): Promise<void> => {
    if (hasMarginChanged) {
      setLoading(true);
      if (typeof props.saveAccessories === 'function') {
        await props?.saveAccessories();
      }
      //Articles here
      let articlesForDNBCalculations = cloneDeep(articles);
      const index = articles.findIndex((e) => e.key === 'customer-advantage');
      if (index > 0) {
        articlesForDNBCalculations[index].priceExclVat = activeMargin?.campaignDiscount;
      }
      const vatCalculation = calculateVat(articlesForDNBCalculations, props?.carType);
      const data = await postAndSaveMargin(
        get(props, 'order.id', ''),
        vatCalculation,
        activeMargin,
        createAndSaveMarginMutation,
        articlesForDNBCalculations,
      );
      if (data) {
        setHasMarginChanged(false);
      }
      setLoading(false);
    }
  };

  const handleReset = (): void => {
    setActiveMargin({
      ...(latestMargin || {}),
    });
    setIsValidCampaignDiscount(true);
    setIsValidImporterDiscount(true);
    setHasMarginChanged(false);
  };

  return (
    <MarginAccordion>
      <Accordion labelContent={<span className="h3">Rabatt</span>}>
        <CalculateDiscount
          activeMargin={activeMargin}
          latestMargin={latestMargin}
          pimSnapshot={get(props, 'order.pimSnapshot', null)}
          setActiveMargin={setActiveMargin}
          vehicleConfiguration={props.vehicleConfiguration}
          articles={articles}
          vatPrices={vatPrices}
          setVatPrices={setVatPrices}
          isValid={isValidCampaignDiscount}
          setIsValid={setIsValidCampaignDiscount}
          carType={props?.carType}
          order={props.order}
        />
        {!get(props, 'order.isAgent', null) && (
          <div className="marginContainer">
            <ToggleSwitch label={'Marginbilde'} checked={checkedIndex} toggleHandler={handleSwitchToggle} />
            {checkedIndex && (
              <div className="marginCartBottomContainer">
                <CalculateImporterDiscount
                  activeMargin={activeMargin}
                  latestMargin={latestMargin}
                  pimSnapshot={get(props, 'order.pimSnapshot', null)}
                  articles={articles}
                  setActiveMargin={setActiveMargin}
                  vehicleConfiguration={props.vehicleConfiguration}
                  isValid={isValidImporterDiscount}
                  setIsValid={setIsValidImporterDiscount}
                  carType={props?.carType}
                  order={props.order}
                />
                <MarginCartDetails margin={latestMargin} />
              </div>
            )}
          </div>
        )}
        <div className="btnGroup">
          <Button
            isLoading={loading}
            colorVariant={'secondary'}
            onClick={saveMargin}
            disabled={
              !hasMarginChanged ||
              (!isValidCampaignDiscount && isValidCampaignDiscount !== null) ||
              (!isValidImporterDiscount && isValidImporterDiscount !== null) ||
              props.isUnavailablePimData
            }>
            <span>Oppdater rabatt</span>
          </Button>
          <Button
            variant={'secondary'}
            colorVariant={'secondary'}
            onClick={handleReset}
            disabled={props.isUnavailablePimData}>
            <span>Nullstill</span>
          </Button>
        </div>
      </Accordion>
    </MarginAccordion>
  );
};
