import { useEffect, useState } from 'react';
import { Events, PreSelectedKeyEnum, eventEmitter } from '../../utils';
import { enablePreSelection } from '../constants';

export const usePreSelectionEquipment = () => {
  const [preSelectSku, setPreSelectSku] = useState(new Map());
  const [isEnablePreSelection, setIsEnablePreSelection] = useState(Boolean);
  const [isPreSelectionError, setIsPreSelectionError] = useState(Boolean);

  const isPreselectionDoneForAllEquip = () => {
    if (
      Array.from(preSelectSku.entries())?.length &&
      preSelectSku?.get(PreSelectedKeyEnum.COLOR)?.status === true &&
      preSelectSku?.get(PreSelectedKeyEnum.INTERIOR)?.status === true &&
      preSelectSku?.get(PreSelectedKeyEnum.RIM)?.status === true &&
      preSelectSku?.get(PreSelectedKeyEnum.LISTVERK)?.status === true &&
      preSelectSku?.get(PreSelectedKeyEnum.TAKLAK)?.status === true &&
      preSelectSku?.get(PreSelectedKeyEnum.EXT_TILLEGG)?.status === true &&
      preSelectSku?.get(PreSelectedKeyEnum.INT_TILLEGG)?.status === true
    ) {
      return true;
    }
    return false;
  };

  const handlePreSelectSku = (skus) => {
    setPreSelectSku(skus);
  };

  const getPreselectedKeyValue = (key: PreSelectedKeyEnum): string => {
    return preSelectSku?.has(key) ? preSelectSku?.get(key)?.sku : null
  }

  const isSKUPreselected = (sku: string, key: string): boolean => {
    if (preSelectSku?.has(key)) {
      const selectedKey = preSelectSku?.get(key);
      const selectedSku = selectedKey?.sku;
      const selectedStatus = selectedKey?.status;
      if (selectedSku === sku && selectedStatus === false) {
        return true;
      }
    }
    return false;
  };

  const updateKeyStatus = (key: string) => {
    if (preSelectSku?.has(key)) {
      const selectedKey = preSelectSku?.get(key);
      preSelectSku.set(key, { ...selectedKey, status: true });
    }

    if (isPreselectionDoneForAllEquip()) {
      console.log('-------DISPATCH ENABLE_PRESELECT_SKU: FASLE---------------');
      eventEmitter.dispatch(Events.ENABLE_PRESELECT_SKU, false);
    }
  };

  const isPreSelectionDone = (key: string) => {
    if (preSelectSku?.has(key)) {
      const selectedKey = preSelectSku?.get(key);
      return selectedKey?.status;
    }
    return true;
  };

  useEffect(() => {
    eventEmitter.subscribe(Events.PRESELECT_SKU, handlePreSelectSku);
    eventEmitter.subscribe(Events.ENABLE_PRESELECT_SKU, setIsEnablePreSelection);
    eventEmitter.subscribe(Events.PRESELECT_SKU_ERROR, setIsPreSelectionError);
    return () => {
      eventEmitter.unsubscribe(Events.PRESELECT_SKU);
      eventEmitter.unsubscribe(Events.ENABLE_PRESELECT_SKU);
      eventEmitter.unsubscribe(Events.PRESELECT_SKU_ERROR);
    };
  }, []);

  useEffect(() => {
    enablePreSelection(isEnablePreSelection);
  }, [isEnablePreSelection]);
  

  return {
    preSelectSku,
    isEnablePreSelection,
    isPreSelectionError,
    isSKUPreselected,
    updateKeyStatus,
    isPreSelectionDone,
    getPreselectedKeyValue
  };
};
