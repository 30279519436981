import React from 'react';
import checkmark from './imgs/check.png';
import IndependentCheck from './imgs/independent-dealer/check.png'
import { View, Text, Image } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { IPackageEquipmentItems } from 'next-common';
import { HorizontalWarpableListStyled } from './common-styles';
import { v4 as uuidv4 } from 'uuid';
import { EquipmentBundleListMakeStyle } from './EquipmentBundleList.style';

interface IEquipmentItem {
  make?: string,
  noicon?: boolean;
  item?: string;
  isBreak?: boolean;
  dealerRole?: string,
}

interface IEquipmentBundle {
  make?: string,
  dealerRole?: string,
  equipmentBundleList?: IPackageEquipmentItems[];
  name: string;
  breakPage: boolean;
}

interface IEquipmentBundleForStockSale {
  make?: string,
  dealerRole?: string,
  equipmentBundleList?: string[];
  breakPage: boolean;
}

const EquipmentItemStyled: Style = {
  width: '50%',
  flexDirection: 'row',
  marginVertical: 2,
  alignItems: 'center',
  padding: '2 5 2 2',
};

const RowCount = 48;

const EquipmentItem = (props: IEquipmentItem) => {
  const checkIcon = props?.dealerRole === 'DEFAULT' ? checkmark : IndependentCheck;
  return(
    <View style={EquipmentItemStyled} break={props?.isBreak}>
      {!props.noicon && <Image source={checkIcon} style={{ height: 8, width: 8, marginRight: 8, padding: 1 }} />}
      <Text style={EquipmentBundleListMakeStyle[props?.dealerRole][props.make]['equipmentValue']}>{props.item}</Text>
    </View>
  )
};

export const EquipmentBundleList = (props: IEquipmentBundle) => {
  let itemCount = 0;
  return (
    <View style={{ marginBottom: 10 }} break={props.breakPage}>
      <Text style={EquipmentBundleListMakeStyle[props?.dealerRole][props.make]['equipmentLabel']}>{props.name}</Text>
      <View style={HorizontalWarpableListStyled}>
        {props.equipmentBundleList?.map?.((item) => {
          if (itemCount === RowCount) {
            itemCount = 0;
          } else {
            itemCount++;
          }
          return (
            <>
              <EquipmentItem key={uuidv4()} item={item?.name} isBreak={itemCount === RowCount ? true : false} make={props.make} dealerRole={props?.dealerRole} />
              {(!props.equipmentBundleList || props.equipmentBundleList?.length < 1) && (
                <EquipmentItem item="Tom liste" noicon={true} make={props.make} dealerRole={props?.dealerRole} />
              )}
            </>
          );
        })}
        {(!props.equipmentBundleList || props.equipmentBundleList?.length < 1) && (
          <EquipmentItem item="Tom liste" noicon={true} make={props.make} dealerRole={props?.dealerRole} />
        )}
      </View>
    </View>
  );
};

export const EquipmentBundleListForStockSale = (props: IEquipmentBundleForStockSale) => {
  let itemCount = 0;
  return (
    <View break={props.breakPage} wrap={true}>
      <Text style={EquipmentBundleListMakeStyle[props?.dealerRole][props.make]['equipmentLabel']}>{'Standardutstyr'}</Text>
      <View style={HorizontalWarpableListStyled}  wrap={true}>
        {props.equipmentBundleList?.map?.((item) => {
          if (itemCount === RowCount) {
            itemCount = 0;
          } else {
            itemCount++;
          }
          return (
            <>
              <EquipmentItem key={uuidv4()} item={item} isBreak={itemCount === RowCount ? true : false} make={props.make} dealerRole={props?.dealerRole} />
            </>
          );
        })}
        {(!props.equipmentBundleList || props.equipmentBundleList?.length < 1) && (
          <EquipmentItem item="Tom liste" noicon={true} make={props.make} dealerRole={props?.dealerRole} />
        )}
      </View>
    </View>
  );
};

