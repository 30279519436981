import React, { useEffect, useRef, useState } from 'react';
import { OperationVariables, useLazyQuery, useMutation } from '@apollo/client';
import { Cart, ICartLocationProps, ICartProps, ICartSummaryData, ReserveButtonStages } from '@next-components/cart';
import { LoadingSpinner } from '@next-components/loading-spinner';
import { MessagebarSeverityLevel } from '@next-components/messagebar';
import classNames from 'classnames';
import { isFunction, cloneDeep, compact, includes, isEmpty, isEqual, map, uniq, get, defaultTo, isNil, difference } from 'lodash';
import {
  getPimCodeComponents,
  IEquipmentTypes,
  IInterior,
  IOrder,
  IVarnish,
  IVatCalculation,
  IVehicleConfiguration,
  IVehicleConfigurationPricing,
  IVehicleImage,
  IVehicleInput,
  IVehicleOptionInput,
  MAKE,
  NextErrorCode,
  VehicleEquipmentCategoryEnum,
  isEditable,
  IConfiguration,
  getConditionalValue,
  MBApiCalculationFlag,
  INextArticles,
  INextArticle,
  HashMap,
  VehicleReserveStatus,
  ApplicationCode,
  OrderStatus,
  getOneOffTaxFromEquipments,
  getStockEquipmentLevelsFromEquipments,
  IUniqueModelCodeAndSkus,
  invokeProvidedBrandSpecificCode,
  getFactoryEquipmentLevelForFactoryOrder
} from 'next-common';
import { ICrystallizeMappedFactoryEquipmentLevels, IEquipmentIdAndSKU } from 'sales-crystallize-common';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  addChosenOfferMutationParsed,
  addVehiclesToOrderAndVehicleEquipmentMutationParsed,
  addVehiclesToOrderMutationParsed,
  brandsToAllowOrderWithoutSerialNo,
  createExistingOrderMutationParsed,
  createExistingOrderWithVehicleAndVehicleEquipmentMutationParsed,
  createExistingOrderWithVehicleMutationParsed,
  currentVehicle,
  enablePreSelection,
  ERROR_POLICY,
  INotificationPageProps,
  IRulesTable,
  isDemoOrder,
  ISellAllCarsFilterBuilder,
  isStockOrder,
  IVehicleChanged,
  IVehicleListOrderProps,
  IVehiclePageProps,
  IVehicleStock,
  IVehicleStockForSellAllCars,
  PAGE_TYPE,
  Routes,
  statusChecked,
  useDisableScroll,
  useDisableSkus,
  useEnableSkus,
  usePreSelectionEquipment,
  useSkusPriceUpdate,
} from '../../../common';
import {
  eventEmitter,
  Events,
  hasReservationBlockingCancellation,
  validateDateBetweenTwoDates,
  VehicleContext,
} from '../../../utils';
import {
  calculateVatPriceFromVehicleList,
  checkAndUpdateCart,
  createStockKey,
  fetchOriginalPriceOfSelectables,
  findSelectableSkus,
  formatKey,
  getAppendingNumericIdFromUrl,
  getAvailableBaseModel,
  getExactAndNonExactMatchSpecificSeller,
  getExactAndNonExactMatchVehicleList,
  getFactoryOptionInputs,
  getFactoryVehicleInput,
  getInteriorData,
  getListAfterInitialFilter,
  getMake,
  getPEUBaseModelFilteredList,
  getVarnishData,
  getVehicleCountBySeller,
  getVehicleCountOnSkus,
  getVehiclesForBaseModel,
  IDescriptionDialogContent,
  isBaseModel,
  isChangeVehicleModelOnly,
  isColorAvailableInArticle,
  isColorFound,
  isEquipmentFound,
  isInteriorAvailableInArticle,
  isInteriorFound,
  ISkuVehicleCount,
  isOfferChange,
  isOfferValidationPassed,
  isValidBrandForSelectables,
  isValidFactoryOrder,
  isVariantAvailableInArticle,
  locationList,
  prepareArticles,
  prepareDeliveryChargeInput,
  prepareEquipmentFromVehicleConfig,
  prepareEquipments,
  prepareDiscountInput,
  prepareOptions,
  prepareTyresDefaultInputDuringSelection,
  prepareVehicleConfig,
  removeInvalidValuesFromArray,
  updateDataOnChangedVehicle,
  updatedPricingForSelectableAndOptions,
  updateVehicleModel,
  VehicleConfig,
  isOrderEditable,
  getManufactureModelId,
  getModelCode,
  getSelectedEquipmentInCart,
  filterSelectedEquipmentInCart,
  updateArticlesWithOrderAccessoryArticles,
  getModelSeries,
  OtherDealerVehicleReserveOrSignValidationMsg,
  isValidStockSaleOrder,
  getCarTypeOfSelectedModel,
  getStructuredVehicleData,
  stockLocation,
  getVehicleStockForKiaSku,
  mapIsDisableVehicles,
  filterVehicleStockForKia,
  rulesPayloadMapper,
  getDisableSkuListOnLoad,
  removeDuplicateObjectFromArray,
  preparePreSelectedSKUList,
  prepareCartDataFromRulesTable,
  getSelectionDataFromRulesTable,
  prepareArticlesFromRulesTable,
  prepareRulesPayloadFromSelectedData,
  reApplyPreSelectionOnSkus,
  updateCartDataForDefaultSkus,
  updateArticlesForDefaultSkus,
} from '../../../utils/sales';
import {
  getCurrentModelData,
  getCurrentVariantData,
  getMappedEquipement,
  updateArticlePrices,
  updateCartDataPrices,
} from '../../../utils/sales/vehicleCart';
import {
  CustomErrorNotification,
  ErrorNotification,
  NextErrorNotification,
  ProdDateWarning,
  ValidationErrorCode,
} from '../../notifications';
import { ToastArea } from '../sales-common/ToastArea';
import { IVehicleStockWithSegregatedTypes } from '../sales-wizard/vehicleSegregationWorker';
import { ICartDataProps, IListverkChangeProps, IRimChangeProps, ResultGrid } from './ResultGrid';
import { TabId, Tabs } from '@next-components/tabs';
import { VehiclePickerPageMainStyled, VehiclePickerPageStyled } from './VehiclePickerPage.styled';
import { useNavigate } from 'react-router-dom';
import { useVatStateCalculationHandler } from '../../../common/hooks/useVatCalculation';
import { getActiveStep, getStepData, IStepMenuData } from '../sales-wizard/sales-wizard-menu';
import { SalesWizardButtonMenu } from '../sales-wizard/sales-wizard-menu/SalesWizardButtonMenu';
import { fetchSerialNumberList } from '../../common-components/DynamicListMer/DynamicList.utils';
import { VehicleStockListDialog } from './VehicleStockListDialog';
import { VehicleStockListDialogStyled } from './VehicleStockListDialog/VehicleStockListDialog.styled';
import { MBConfigModal } from '../sales-common/MBConfigModal';
import { CustomAlertPopup } from '../../common-components/custom-DeletePopup';
import { IAlertPopup } from '../sales-wizard';
import { IconType } from '@next-components/bos-icon';
import { saleAllCarsFilterWorker } from '../../../utils/sales/salsAllCarsWorker';
import { ArticleType, VatCode } from 'sales-common';
import { queryVehicleDataFromPIM } from '../../../graphql';

export interface IVehicleFactoryItem {
  color: string;
  colorPrice: number;
  interiorCode: string;
  interiorPrice: number;
  expectedProductionDate: string;
  totalPrice: string | number;
  colorId?: string;
  interiorId?: string;
}
export interface IVehicleResult {
  vehicles?: IVehicleConfiguration[];
  loading?: boolean;
  error?: any;
}
export interface IModelPackages {
  id: string;
  name: string;
  additionalPrice?: number;
  colors: IVarnish[];
  image: IVehicleImage;
  interiors: IInterior[];
  selectableEquipment: IEquipmentTypes[];
  rims: IEquipmentTypes[];
  price: number;
  isRecommended: boolean;
  sku: string;
  modelCode: string;
  modelSeries?: string;
  mappedEquipment: IEquipmentIdAndSKU[];
  vatCode: string;
  oneOffTaxWeight: number;
  deliveryCharge: number;
  customerAdvantage: number;
  customerAdvantageExcludingVat?: number;
  packageDescriptionContent?: IDescriptionDialogContent;
  pricing?: IVehicleConfigurationPricing;
  manufacturerComponentIdModel?: string;
  path?: string;
}
export interface IVehicleModel {
  id: string;
  name: string;
  salesPackages: IModelPackages[];
  basePrice: number;
  code: string;
  packageSkus: string[];
  vatCode: string;
  stockCount?: number;
  vehicleType?: string;
  baseCostPriceExclVat: number;
}
export interface IPageProps {
  cartData: ICartProps;
  vehicleList: IVehicleConfiguration[];
  vehicleData: IVehicleModel[];
  selectedColorId: string;
  selectedColorName: string;
  selectedInteriorId: string;
  selectedInteriorName: string;
  selectableEquipments?: IEquipmentTypes[];
  selectedModelName: string;
  selectedVariant: IModelPackages;
  rimsEquipments?: IEquipmentTypes;
  listverkEquipments?: IEquipmentTypes;
  taklakkEquipments?: IEquipmentTypes;
  selectedSerialNo: number;
  isFactoryOrderEnable: boolean;
  offerMake: MAKE;
  isFactoryOrder?: boolean;
  availableVehicles: IVehicleStockWithSegregatedTypes[];
  filteredVehicleList: IVehicleStockWithSegregatedTypes[];
  group?: string;
  vehicleSku: string;
  vehicleModelCode: string;
  vehicleReservedStatus: boolean;
  validLocationIds: string[];
  vehicleStock?: any;
  vehicleModelId: string;
  vehicleVariantId: string;
  expectedProductionDate: Date;
  interiorAvailableInPimFlag: boolean;
  colorAvailableInPimFlag: boolean;
  order: IOrder;
  mappedEquipment: IEquipmentIdAndSKU[];
  basePrice: [number, number];
  isVehicleStockQueryCalled?: boolean;
  isOwnLocation(locationId: string): boolean;
  isImporterLocation(locationId: string): boolean;
  setIsLoading: (loading: boolean) => void;
}

export interface IVehiclePickerPageProps extends INotificationPageProps, IVehiclePageProps {
  pageProps?: IPageProps;
  vehicleModelData?: IVehicleModel[];
  vehicle?: IVehicleConfiguration;
  getUpdateOrderVariables: (vehicle: IVehicleConfiguration) => IVehicleListOrderProps;
  isCalled?: boolean;
  isShowDeleteModel?: boolean;
  isShowDeleteButton?: boolean;
  setPickerLoading?: (value: boolean) => void;
  isPickerLoading?: boolean;
  setIsCalled?: (called: boolean) => void;
  setVehicleConfigLoading?: (value: boolean) => void;
  vehicleConfigLoading?: boolean;
  hasUpdateEventOccurs?: boolean;
  setHasUpdateEventOccurs?: (value: boolean) => void;
  configuration?: IConfiguration;
  stepMenuData?: IStepMenuData;
  nonExactMatchCount?: number;
  exactMatchCount?: number;
  setExactMatchCount?: (exactMatchCount?: number) => void;
  setNonExactMatchCount?: (nonExactMatchCount?: number) => void;
  isElectricCar?: boolean;
  modelOptions?: HashMap;
  vehicleStockDataForSellAllCars?: [IVehicleStockForSellAllCars];
  isLoading?: boolean;
  isVehicleListDataLoaded?: boolean;
  setIsLoading?: (isLoading?: boolean) => void;
  setIsShowDeleteModel?: (isShowDeleteModel: boolean) => void;
  deleteOrderData?: IAlertPopup;
  importerLocationIds?: string[];
  dealerIds?: string[];
  isVehicleRemoved?: boolean;
  setIsVehicleRemoved?: (isRemoved: boolean) => void;
  rootUrl?: string;
  dataFromFactoryEquipLevels?: IUniqueModelCodeAndSkus;
  setIsChangeVehicle?: (value: boolean) => void;
}

export interface IVehicleCountBySeller {
  own: {
    own?: number;
    vehicles: IVehicleStockWithSegregatedTypes[];
  };
  port: {
    importer?: number;
    vehicles: IVehicleStockWithSegregatedTypes[];
  };
  other: {
    dealer?: number;
    vehicles: IVehicleStockWithSegregatedTypes[];
  };
}

export interface IDateValidation {
  isValid: boolean;
  reason: string;
  isError?: boolean;
  selectableName?: string;
}

export const VehiclePickerPage = (props: IVehiclePickerPageProps) => {
  const { pageProps, vehicleConfigLoading } = props;
  const [isVehicleListModal, setIsVehicleListModal] = useState<boolean>(false);
  const [selectedModelId, setSelectedModelId] = useState<string>(pageProps?.vehicleModelId);
  const [selectedModelCode, setSelectedModelCode] = useState<string>(pageProps?.vehicleModelCode);
  const [selectedModelName, setSelectedModelName] = useState<string>(pageProps?.selectedModelName);
  const [selectedVariantSku, setSelectedVariantSku] = useState<string>(pageProps?.selectedVariant?.sku);
  const [selectedVariantName, setSelectedVariantName] = useState<string>(pageProps?.selectedVariant?.name);
  const [selectedVariantId, setSelectedVariantId] = useState<string>(pageProps?.vehicleVariantId);
  const [selectedModelSku, setSelectedModelSku] = useState<string>(pageProps?.vehicleSku);
  const [cartData, setCartData] = useState<ICartProps>(pageProps?.cartData);
  const [selectedColorId, setSelectedColorId] = useState<string>(pageProps?.selectedColorId);
  const [selectedColorName, setSelectedColorName] = useState<string>(pageProps?.selectedColorName);
  const [selectedInteriorId, setSelectedInteriorId] = useState<string>(pageProps?.selectedInteriorId);
  const [selectedInteriorName, setSelectedInteriorName] = useState<string>(pageProps?.selectedInteriorName);
  const [selectedRims, setSelectedRims] = useState<IEquipmentTypes>(defaultTo(pageProps?.rimsEquipments, null));
  const [selectedEquipmentIds, setSelectedEquipmentIds] = useState<IEquipmentTypes[]>(
    pageProps?.selectableEquipments ?? [],
  );
  const [selectedListverk, setSelectedListverk] = useState<IEquipmentTypes>(pageProps?.listverkEquipments ?? null);
  const [selectedTaklakk, setSelectedTaklakk] = useState<IEquipmentTypes>(pageProps?.taklakkEquipments ?? null);
  const [location, setLocation] = useState<ICartLocationProps[]>([]);
  const [filteredVehicleList, setFilteredVehicleList] = useState<IVehicleStockWithSegregatedTypes[]>(
    pageProps?.filteredVehicleList,
  );
  const [isChange, setIsChange] = useState<boolean>(false);
  const [queryMake] = useQueryParam('merke', StringParam);
  const [queryModelSeries] = useQueryParam('modellserie', StringParam);
  const [vehicleStock, setVehicleStock] = useState(pageProps?.vehicleStock);
  const [availableVehicles, setAvailableVehicles] = useState<IVehicleStockWithSegregatedTypes[]>(
    pageProps?.availableVehicles,
  );
  const [isFactoryOrderEnable, setIsFactoryOrderEnable] = useState<boolean>(pageProps?.isFactoryOrderEnable);
  const [vehicle, setVehicle] = useState<IVehicleConfiguration>(props.vehicle);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [group, setGroup] = useState<string>(pageProps?.group);
  const offerMake = pageProps?.offerMake;
  const [vehicleKey, setVehicleKey] = useState<string>(null);
  const [expectedProductionDate, setExpectedProductionDate] = useState<Date>(pageProps.expectedProductionDate);
  const [updateVehicleList, setUpdateVehicleList] = useState<boolean>(false);
  const [allSkuVehicleCount, setAllSkuVehicleCount] = useState<ISkuVehicleCount>(null);
  const [notifications, setNotifications] = React.useState<JSX.Element[]>([]);
  const [packageSkus, setPackageSkus] = React.useState<string[]>([]);
  const [selectableRulePayload, setSelectableRulePayload] = React.useState<string[]>([]);
  const { addVehicleData } = React.useContext(VehicleContext);
  const { isDisableSKUonChange, isDisableSKUonLoad, onloadDisableSku } = useDisableSkus();
  const { isPreSelectionError } = usePreSelectionEquipment();
  const isEnablePreSelection = enablePreSelection();
  const [rulesTableData, setRulesTableData] = useState<IRulesTable>(null);
  const { isEnableSKUonChange } = useEnableSkus();
  const priceUpdate = useSkusPriceUpdate();
  const [vehicleCountBySeller, setVehicleCountBySeller] = useState<IVehicleCountBySeller>({
    own: {
      own: 0,
      vehicles: [],
    },
    port: {
      importer: 0,
      vehicles: [],
    },
    other: {
      dealer: 0,
      vehicles: [],
    },
  });
  const [specificSellerVehicles, setSpecificSellerVehicles] = useState<IVehicleStockWithSegregatedTypes[]>([]);
  const [isSpecificSellerEnabled, setIsSpecificSellerEnabled] = useState<boolean>(false);
  const [vehicleData, setVehicleData] = useState<IVehicleModel[]>(pageProps?.vehicleData || []);
  const [vehicleList, setVehicleList] = useState<IVehicleConfiguration[]>(pageProps?.vehicleList || []);
  const [vehicleListExcludingLocationFilter, setVehicleListExcludingLocationFilter] = useState<
    IVehicleStockWithSegregatedTypes[]
  >([]);
  const [basePrice, setBasePrice] = useState<[number, number]>(pageProps?.basePrice || [0, 0]);
  const [mappedEquipment, setMappedEquipment] = useState<IEquipmentIdAndSKU[]>(pageProps?.mappedEquipment);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updateOrderMutation] = useMutation(createExistingOrderMutationParsed);
  const [updateOrderWithVehicleMutation] = useMutation(createExistingOrderWithVehicleMutationParsed);
  const [updateOrderWithVehicleEquipmentMutation] = useMutation(
    createExistingOrderWithVehicleAndVehicleEquipmentMutationParsed,
  );
  const [addVehiclesToOrder] = useMutation(addVehiclesToOrderMutationParsed);
  const [addVehiclesAndVehicleEquipmentToOrder] = useMutation(addVehiclesToOrderAndVehicleEquipmentMutationParsed);
  const [articles, setArticles] = useState<INextArticle[]>(props?.articles);
  const [selectablePrices, setSelectablePrices] = useState<{ string: number }>(null);
  const [vehicleLocation, setVehicleLocation] = useState<string>('');
  const [raiseWarnNotification, setRaiseWarnNotification] = useState<boolean>(false);
  const [selectedSkus, setSelectedSkus] = useState<string[]>([]);
  const [showReserveButton, setShowReserveButton] = useState<boolean>(true);
  const [showChangeSpecificCarButton, setShowChangeSpecificCarButton] = useState<boolean>(true);
  const [currentActiveTab, setCurrentActiveTab] = useState<TabId>(PAGE_TYPE.CONFIGURE);
  const [saleAllCarsFilters, setSaleAllCarsFilters] = useState<ISellAllCarsFilterBuilder>();
  const [isFactoryOrderButtonDisabled, setIsFactoryOrderButtonDisabled] = useState<boolean>(false);
  const [dataFromFactoryEquipLevels, setDataFromFactoryEquipLevels] = useState<IUniqueModelCodeAndSkus>(props?.dataFromFactoryEquipLevels);
  const [specificSellerVehiclesForExactMatch, setSpecificSellerVehiclesForExactMatch] = useState<
    IVehicleStockWithSegregatedTypes[]
  >([]);
  const [specificSellerVehiclesForNonExactMatch, setSpecificSellerVehiclesForNonExactMatch] = useState<
    IVehicleStockWithSegregatedTypes[]
  >([]);

  const [vehicleListForExactMatch, setVehicleListForExactMatch] = useState<IVehicleStockWithSegregatedTypes[]>([]);
  const [vehicleListForNonExactMatch, setVehicleListForNonExactMatch] = useState<IVehicleStockWithSegregatedTypes[]>(
    [],
  );
  const callWorkers = useRef(null);

  const [addChosenOffer] = useMutation(addChosenOfferMutationParsed, {
    errorPolicy: ERROR_POLICY,
  });
  const isOrderStockType = isStockOrder(pageProps?.order?.lead?.orderType);
  const isOrderDemoType = isDemoOrder(pageProps?.order?.lead?.orderType);
  const activeStep = getActiveStep(props?.rootUrl);
  const stepData = getStepData(activeStep, props.orderId, props?.rootUrl);

  /* The above code is a `useEffect` hook in a TypeScript React component. It is setting up a worker
function `saleAllCarsFilterWorker` to be called and handling the response from the web worker. */
  useEffect(() => {
    callWorkers.current = saleAllCarsFilterWorker();

    if (callWorkers.current) {
      if (callWorkers.current !== null && callWorkers.current !== undefined) {
        callWorkers.current.onmessage = (event) => {
          setSaleAllCarsFilters(event?.data);
        };
      }
    }
  }, []);

  useEffect(() => {
    if (props?.vehicleStockDataForSellAllCars?.length > 0 && vehicleStock?.sizeOfStock()) {
      const allStock = vehicleStock.getStock('ALL');
      callWorkers.current.postMessage({ vehicleStock: allStock });
    }
  }, [props?.vehicleStockDataForSellAllCars]);

  useEffect(() => {
    const exactMatchSerialNo = fetchSerialNumberList(vehicleListForExactMatch, specificSellerVehiclesForExactMatch);
    const nonExactMatchSerialNo = fetchSerialNumberList(
      vehicleListForNonExactMatch,
      specificSellerVehiclesForNonExactMatch,
    );
    props?.setExactMatchCount(exactMatchSerialNo?.length || 0);
    props?.setNonExactMatchCount(nonExactMatchSerialNo?.length || 0);
    if (selectedModelId && !selectedVariantId) {
      const data = props?.vehicleModelData?.filter((vehicle) => vehicle?.id === selectedModelId);
      const count = data?.[0]?.stockCount;
      props?.setNonExactMatchCount(count || 0);
    }
  }, [
    vehicleListForExactMatch,
    specificSellerVehiclesForExactMatch,
    vehicleListForNonExactMatch,
    specificSellerVehiclesForNonExactMatch,
  ]);
  const navigate = useNavigate();
  const [showMbAPIPopup, setShowMbAPIPopup] = useState<boolean>(false);
  const [mbAPIErrorCode, setMBAPIErrorCode] = useState<number>(null);
  const [isFactoryOrderClicked, setIsFactoryOrderClicked] = useState<boolean>(false);
  const carType =
    pageProps?.order?.pimSnapshot?.vehicleType ??
    props?.vehicle?.model?.vehicleType ??
    getCarTypeOfSelectedModel(props.vehicleModelData, selectedModelId);

  useDisableScroll(isVehicleListModal);
  const isStockSaleOrder = isValidStockSaleOrder(pageProps?.order, props?.configuration);

  useEffect(() => {
    eventEmitter.subscribe(Events.DISABLE_RESERVE_BTN, pageProps.setIsLoading);
    return () => {
      eventEmitter.unsubscribe(Events.DISABLE_RESERVE_BTN);
    };
  }, []);

  useEffect(() => {
    eventEmitter.subscribe(Events.UPDATE_VEHICLE_LIST, setUpdateVehicleList);
    return () => {
      eventEmitter.unsubscribe(Events.UPDATE_VEHICLE_LIST);
    };
  }, []);

  useEffect(() => {
    if (isPreSelectionError) {
      eventEmitter.dispatch(Events.PRESELECT_SKU_ERROR, false);
      setNotifications([
        <ErrorNotification
          key="preSelectionError"
          errorCode={ValidationErrorCode.PRESELECTION_ERROR}
          severityLevel={MessagebarSeverityLevel.ERROR}
          onClose={() => setNotifications([])}
        />,
      ]);
    }
  }, [isPreSelectionError]);

  useEffect(() => {
    if (isEditable(pageProps?.order?.status)) {
      setDisabled(true);
      setShowReserveButton(false);
      setShowChangeSpecificCarButton(false);
    }
    if (isStockOrder(pageProps?.order?.lead?.orderType) || isDemoOrder(pageProps?.order?.lead?.orderType)) {
      setShowReserveButton(false);
    }
    if (props?.reserveButtonStage === ReserveButtonStages.RESERVED) {
      setShowChangeSpecificCarButton(false);
    }
    if (props?.isFactoryOrder) {
      setShowChangeSpecificCarButton(isChange);
    }
  }, [pageProps?.order?.status, props?.reserveButtonStage, props?.isFactoryOrder, isChange]);

  useEffect(() => {
    //It will hide "Bytt spesifikk bil [ Change specific car ]" button for STOCK_SALE and STOCK_SALE_CRM order's.
    if (isStockSaleOrder) {
      //It will disabled all the selection of step_1
      setDisabled(true);
      setShowChangeSpecificCarButton(false);
    }
  }, [isStockSaleOrder]);

  useEffect(() => {
    props?.setHasUpdateEventOccurs(false);
    setCartData(pageProps?.cartData);
    setIsChange(false);
    props?.setIsChangeVehicle(false);
    setArticles(props?.articles);
    setSelectedRims(pageProps?.rimsEquipments ?? null);
    setSelectedListverk(pageProps?.listverkEquipments ?? null);
    setSelectedTaklakk(pageProps?.taklakkEquipments ?? null);
    // In case of removed equipments due to disable or unselect rules,
    // they should be re-added from order.
  }, [pageProps?.cartData, props?.hasUpdateEventOccurs]);

  useEffect(() => {
    //Check for latest update of data
    if (props?.vehicle?.salesPackages?.[0]?.id === pageProps?.vehicleVariantId) setArticlesWithRequiredCheck();
  }, [props?.articles]);

  useEffect(() => {
    if (pageProps.offerMake === MAKE.Mercedes) {
      const savedProdDate = pageProps?.order?.vehicles?.[0]?.orderFromFactoryDetails?.expectedProductionDate;
      const expectedProdDateForFactoryOrder = props?.configuration?.features?.expectedProdDateForFactoryOrder;
      const expectedProductionDateMB = expectedProdDateForFactoryOrder
        ?.map((item) => {
          const [label, date] = item?.split(': ')?.map((part) => part?.trim());
          return { label, date };
        })
        ?.find((entry) => entry?.label === 'MER')?.date;

      const finalDate = savedProdDate ? new Date(savedProdDate) : new Date(expectedProductionDateMB);
      setExpectedProductionDate(finalDate);
    } else {
      setExpectedProductionDate(pageProps?.expectedProductionDate);
    }
  }, [pageProps.offerMake, pageProps?.order, pageProps?.expectedProductionDate]);

  const setArticlesWithRequiredCheck = () => {
    if (isVariantAvailableInArticle(pageProps?.vehicleVariantId, props.articles)) {
      const isColorAvailable = isColorAvailableInArticle(pageProps?.order, pageProps?.selectedColorId, props.articles);

      const isInteriorAvailable = isInteriorAvailableInArticle(
        pageProps?.order,
        pageProps?.selectedInteriorId,
        props.articles,
      );

      if (isColorAvailable && isInteriorAvailable) {
        setArticles(props?.articles);
      }
    }
  };

  useEffect(() => {
    // only load the states when vehicle-config query is resolved,
    // this would make sure, order and vehicle-config are synced.
    if (!vehicleConfigLoading) {
      if (pageProps?.vehicleModelId) {
        setSelectedModelId(pageProps?.vehicleModelId);
      }
      if (pageProps?.vehicleModelCode) {
        setSelectedModelCode(pageProps?.vehicleModelCode);
      }
      if (pageProps?.vehicleVariantId) {
        setSelectedVariantId(pageProps?.vehicleVariantId);
      }
      if (pageProps?.selectedModelName) {
        setSelectedModelName(pageProps?.selectedModelName);
      }
      if (pageProps?.selectedVariant?.name) {
        setSelectedVariantName(pageProps?.selectedVariant?.name);
      }
      if (pageProps?.selectedVariant?.sku) {
        setSelectedVariantSku(pageProps?.selectedVariant?.sku);
      }
      if (pageProps?.vehicleSku) {
        setSelectedModelSku(pageProps?.vehicleSku);
      }
      if (pageProps?.selectedColorId) {
        setSelectedColorId(pageProps?.selectedColorId);
      }
      if (pageProps?.selectedColorName) {
        setSelectedColorName(pageProps?.selectedColorName);
      }
      if (pageProps?.selectedInteriorId) {
        setSelectedInteriorId(pageProps?.selectedInteriorId);
      }
      if (pageProps?.selectedInteriorName) {
        setSelectedInteriorName(pageProps?.selectedInteriorName);
      }
      if (!isEqual(pageProps?.rimsEquipments, selectedRims)) {
        setSelectedRims(pageProps?.rimsEquipments);
      }
      if (!isEqual(pageProps?.selectableEquipments, selectedEquipmentIds)) {
        setSelectedEquipmentIds(pageProps?.selectableEquipments);
      }
      if (!isEqual(pageProps?.listverkEquipments, selectedListverk)) {
        setSelectedListverk(pageProps?.listverkEquipments);
      }
      if (!isEqual(pageProps?.taklakkEquipments, selectedTaklakk)) {
        setSelectedTaklakk(pageProps?.taklakkEquipments);
      }
      if (pageProps?.mappedEquipment?.length) {
        setMappedEquipment(pageProps?.mappedEquipment);
      }
      if (pageProps?.vehicleStock) {
        setVehicleStock(pageProps?.vehicleStock);
      }
      if (pageProps?.availableVehicles) {
        setAvailableVehicles(pageProps?.availableVehicles);
      }
      if (pageProps?.group) {
        setGroup(pageProps?.group);
      }
      if (pageProps?.isFactoryOrderEnable) {
        setIsFactoryOrderEnable(pageProps?.isFactoryOrderEnable);
      }
      if (pageProps?.basePrice) {
        setBasePrice(pageProps?.basePrice);
      }
      if (pageProps?.filteredVehicleList) {
        setFilteredVehicleList(pageProps?.filteredVehicleList);
      }
      if (pageProps?.vehicleData) {
        setVehicleData(pageProps?.vehicleData)
      }
      if (pageProps?.vehicleList) {
        setVehicleList(pageProps?.vehicleList || [])
      }
      if (props?.vehicle) {
        setVehicle(props.vehicle);
      }
      if (props?.hasUpdateEventOccurs) {
        props?.setHasUpdateEventOccurs(false);
      }
      if (props.dataFromFactoryEquipLevels) {
        const data = getStockEquipmentLevelsFromEquipments(pageProps?.selectableEquipments, pageProps?.vehicleList?.[0]);
        setDataFromFactoryEquipLevels(data)
      }
    }
  }, [
    pageProps?.selectedModelName,
    pageProps?.selectedVariant?.name,
    pageProps?.selectedVariant?.sku,
    pageProps?.selectableEquipments,
    pageProps?.rimsEquipments,
    pageProps?.listverkEquipments,
    pageProps?.taklakkEquipments,
    pageProps?.selectedColorId,
    pageProps?.selectedColorName,
    pageProps?.selectedInteriorId,
    pageProps?.selectedInteriorName,
    pageProps?.vehicleModelCode,
    pageProps?.vehicleVariantId,
    pageProps?.vehicleModelId,
    pageProps?.mappedEquipment,
    pageProps?.vehicleStock,
    pageProps?.availableVehicles,
    pageProps?.group,
    pageProps?.isFactoryOrderEnable,
    pageProps?.basePrice,
    pageProps?.filteredVehicleList,
    pageProps?.vehicleData,
    pageProps?.vehicleList,
    props.vehicle,
    vehicleConfigLoading,
    props.dataFromFactoryEquipLevels
  ]);

  const { finalPrice } = useVatStateCalculationHandler({
    articles,
    carType,
  });

  // Disable state update from DB data, skip the sku if there are no data in PIM,
  // as this result in broken disable functionlity
  useEffect(() => {
    if (
      pageProps?.selectedColorId ||
      pageProps?.selectedInteriorId ||
      pageProps?.selectableEquipments?.length ||
      pageProps?.rimsEquipments ||
      pageProps?.listverkEquipments ||
      pageProps?.taklakkEquipments
    ) {
      let skus = compact([
        ...(pageProps?.selectableEquipments?.map((selectable) => selectable.sku) ?? []),
        pageProps?.rimsEquipments?.sku,
        pageProps?.listverkEquipments?.sku,
        pageProps?.taklakkEquipments?.sku,
      ]);

      if (pageProps?.order?.vehicles?.length)
        skus = compact([
          pageProps?.colorAvailableInPimFlag ? pageProps?.selectedColorId : null,
          pageProps?.interiorAvailableInPimFlag ? pageProps?.selectedInteriorId : null,
          ...skus,
        ]);
      else skus = compact([pageProps?.selectedColorId, pageProps?.selectedInteriorId, ...skus]);

      const uniqSkus = uniq([...skus]);
      setSelectableRulePayload(uniqSkus);
    }
  }, [
    pageProps?.selectedColorId,
    pageProps?.selectedInteriorId,
    pageProps?.colorAvailableInPimFlag,
    pageProps?.interiorAvailableInPimFlag,
    pageProps?.rimsEquipments,
    pageProps?.selectableEquipments,
    pageProps?.listverkEquipments,
    pageProps?.taklakkEquipments,
    vehicle,
  ]);

  useEffect(() => {
    if (updateVehicleList) {
      // update vehicle list & vehilce list count
      updateAvailableVehicleList();

      // Vehicle Count of all vehicle Additions
      if (currentVehicle()?.modelId) updateVehicleCountForSkus(currentVehicle()?.modelId);
      else setAllSkuVehicleCount(null);
      setUpdateVehicleList(false);
    }
  }, [updateVehicleList]);

  useEffect(() => {
    if (isOrderStockType) {
      setLocation(stockLocation);
    } else {
      setLocation(locationList);
    }
  }, [pageProps?.order]);

  useEffect(() => {
    const make: MAKE = getMake(vehicleList?.[0], queryMake);
    const isCurrentBrandAllow: boolean = includes(brandsToAllowOrderWithoutSerialNo, make);
    const allEquipments = compact([
      ...(selectedEquipmentIds ?? []),
      selectedRims ?? null,
      selectedListverk ?? null,
      selectedTaklakk ?? null,
    ]);
    const isValid = isCurrentBrandAllow
      ? isValidFactoryOrder(
        vehicleList?.[0],
        selectedColorId,
        selectedInteriorId,
        queryMake,
        selectedModelCode,
        allEquipments,
      )
      : isCurrentBrandAllow;
    setIsFactoryOrderEnable(isValid);
  }, [
    selectedColorId,
    selectedInteriorId,
    selectedModelCode,
    selectedEquipmentIds,
    selectedRims,
    selectedListverk,
    selectedTaklakk,
    vehicleConfigLoading,
    vehicleList,
  ]);

  useEffect(() => {
    const isValidData = selectedVariantSku === vehicleList?.[0]?.sku;
    if (invokeProvidedBrandSpecificCode(MAKE.Kia, { vehicleConfiguration: vehicleList?.[0] }) && selectedVariantId && isValidData) {
      const calculatedOneOffTax = getOneOffTaxFromEquipments(vehicleList?.[0], pageProps.order?.equipments);
      const factoryEquipmentLevel = getFactoryEquipmentLevelForFactoryOrder(vehicleList?.[0], pageProps.order?.equipments);
      if (isNil(factoryEquipmentLevel) || isNil(calculatedOneOffTax)) {
        setIsFactoryOrderButtonDisabled(true);
      } else {
        setIsFactoryOrderButtonDisabled(false);
      }
      //Update vehicle count beside sales package name
      const kiaStock = getVehicleStockForKiaSku(vehicleList, group, vehicleStock)
      setAllSkuVehicleCount({ [selectedVariantSku]: kiaStock?.length || 0 });
    }
  }, [vehicleList, selectedVariantId])

  useEffect(() => {
    if (selectedModelCode && !invokeProvidedBrandSpecificCode(MAKE.Kia, { order: pageProps?.order })) {
      const key = createStockKey(group, selectedModelCode);
      setVehicleKey(key);
      currentVehicle({ key, modelId: currentVehicle()?.modelId });
      if (vehicleStock?.sizeOfStock()) updateAvailableVehicleList(key);
      else setAvailableVehicles([]);
    }
  }, [selectedModelCode, selectedModelSku, packageSkus, selectedModelId, selectedVariantId]);

  useEffect(() => {
    if (selectedModelId) {
      currentVehicle({ key: currentVehicle()?.key, modelId: selectedModelId });
      updateVehicleCountForSkus(selectedModelId);
    } else {
      setAllSkuVehicleCount(null);
    }
  }, [props.vehicleModelData, selectedModelId, pageProps?.isVehicleStockQueryCalled]);


  const chosenOffer = async (calculationId) => {
    const { data } = await addChosenOffer({
      variables: {
        orderId: pageProps?.order?.id,
        calculationId: calculationId,
        nextArticles: null,
      },
    });

    if (data?.addChosenOffer.__typename === 'NextError') {
      props.raiseNotification(
        <NextErrorNotification
          key="chooseOfferFail"
          orderId={pageProps?.order?.id}
          errorCode={data?.addChosenOffer?.errorCode}
          onClose={() => props?.clearNotifications('chooseOfferFail')}
        />,
      );
      setIsLoading(false);
    }
  };

  const updateVehicleCountForSkus = (modelId = '') => {
    if (props.vehicleModelData?.length && vehicleStock?.sizeOfStock()) {
      const currentVehicleModel: IVehicleModel[] = props.vehicleModelData?.filter(
        (currentVehicle: IVehicleModel) => currentVehicle?.id === modelId,
      );

      const packageSkuList = currentVehicleModel?.[0]?.packageSkus || [];
      setPackageSkus(packageSkuList);

      if (!invokeProvidedBrandSpecificCode(MAKE.Kia, { order: pageProps?.order })) {
        let skuWithVehicleCount: ISkuVehicleCount;
        let vehicleWithOptionCount = {};
        const { isBaseModelAvailable, baseModel } = getAvailableBaseModel(packageSkuList, props.modelOptions);

        packageSkuList?.forEach((sku) => {
          skuWithVehicleCount = { ...skuWithVehicleCount, [sku]: 0 };
        });
        let baseModelDetail = [];
        packageSkuList?.forEach((currentSku, index) => {
          const { articleNumber } = getPimCodeComponents(currentSku);
          let modelCode = getModelCode(currentSku, props.modelOptions);

          const key = createStockKey(group, formatKey(modelCode));
          const selectedVehicleStock: IVehicleStock[] = vehicleStock?.getStock(key);

          if (selectedVehicleStock?.length) {
            if (isBaseModelAvailable && isBaseModel(baseModel, currentSku)) {
              baseModelDetail = [
                ...baseModelDetail,
                {
                  selectedVehicleStock,
                  modelCode: packageSkuList[index],
                },
              ];
            } else {
              const vehicleCount = getVehicleCountOnSkus(currentSku, offerMake, selectedVehicleStock, isBaseModelAvailable);
              skuWithVehicleCount[packageSkuList[index]] = vehicleCount?.withOptions;
              vehicleWithOptionCount = {
                ...vehicleWithOptionCount,
                [articleNumber]: (vehicleWithOptionCount[articleNumber] || 0) + vehicleCount?.withOptions,
              };
            }
          }
        });

        if (baseModelDetail?.length) {
          const vehicleCount = cloneDeep(skuWithVehicleCount);
          skuWithVehicleCount = updateVehicleCountForBaseModel(vehicleCount, vehicleWithOptionCount, baseModelDetail);
        }
        setAllSkuVehicleCount(skuWithVehicleCount);
      }
    }
  };

  const updateVehicleCountForBaseModel = (
    count: ISkuVehicleCount,
    vehicleWithOptionCount,
    baseModelDetail = [],
  ): ISkuVehicleCount => {
    const vehicleCount = cloneDeep(count);
    baseModelDetail?.forEach((currentModelDetail, index) => {
      const filteredStock = baseModelDetail?.[index]?.selectedVehicleStock;
      if (offerMake === MAKE.Peugeot) {
        const baseModelFilterList = getPEUBaseModelFilteredList(filteredStock, offerMake);
        vehicleCount[baseModelDetail?.[index]?.modelCode] = baseModelFilterList?.length;
      } else {
        vehicleCount[baseModelDetail?.[index]?.modelCode] =
          filteredStock?.length - (vehicleWithOptionCount?.[currentModelDetail?.modelCode] || 0);
      }
    });
    return vehicleCount;
  };

  //vehicle count logic
  useEffect(() => {
    if (availableVehicles?.length) {
      const selectableSkus: string[] = selectedEquipmentIds?.map((element) => element?.sku);
      if (selectedRims) selectableSkus?.push(selectedRims?.sku);
      if (selectedListverk) selectableSkus?.push(selectedListverk?.sku);
      if (selectedTaklakk) selectableSkus?.push(selectedTaklakk?.sku);
      setSelectedSkus(compact([...(selectableSkus || []), selectedColorId, selectedInteriorId]));

      let updatedVehicleList = [];
      //Perform Filter based on selected options
      if (invokeProvidedBrandSpecificCode(MAKE.Kia, { order: pageProps?.order })) {
        const stockAfterEqipmentFilters = selectableSkus?.length ? filterVehicleStockForKia(dataFromFactoryEquipLevels, group, vehicleStock) : availableVehicles;
        updatedVehicleList = stockAfterEqipmentFilters?.filter(
          (vehicle) =>
            isColorFound(vehicle, selectedColorId) &&
            isInteriorFound(vehicle, selectedInteriorId, offerMake)
        );
      } else {
        updatedVehicleList = availableVehicles?.filter(
          (vehicle) =>
            isColorFound(vehicle, selectedColorId) &&
            isInteriorFound(vehicle, selectedInteriorId, offerMake) &&
            isEquipmentFound(vehicle, selectableSkus, offerMake),
        );
      }

      setVehicleListExcludingLocationFilter([...updatedVehicleList]);
      setFilteredVehicleList(updatedVehicleList);
    } else {
      setFilteredVehicleList([]);
      setVehicleListExcludingLocationFilter([]);
    }
  }, [
    availableVehicles,
    selectedColorId,
    selectedInteriorId,
    selectedEquipmentIds,
    selectedRims,
    selectedListverk,
    selectedTaklakk,
  ]);

  const getVehicleCountBySellerArgs = {
    isImporterLocation: pageProps?.isImporterLocation,
    isOwnLocation: pageProps?.isOwnLocation,
  };

  // Creating vehicles count for the different sellers
  useEffect(() => {
    const availableVehilclesExcludingLocationFilter = [...vehicleListExcludingLocationFilter];
    const count = getVehicleCountBySeller(availableVehilclesExcludingLocationFilter, getVehicleCountBySellerArgs);
    setVehicleCountBySeller(count);
  }, [filteredVehicleList]);

  useEffect(() => {
    if (
      hasReservationBlockingCancellation(pageProps?.order?.reserveDetails) &&
      pageProps?.order?.reserveDetails?.vehicleReserveStatus?.toString() ===
      VehicleReserveStatus[VehicleReserveStatus.RESERVED] &&
      pageProps?.order?.status?.toString() !== OrderStatus[OrderStatus.CONFIRMED]
    ) {
      setDisabled(true);
      props.raiseNotification(
        <ErrorNotification
          key="reservedCar"
          severityLevel={MessagebarSeverityLevel.WARNING}
          errorCode={ValidationErrorCode.RESERVED_CAR_VALIDATION}
          onClose={() => props?.clearNotifications('reservedCar')}
        />,
      );
    }
  }, [pageProps?.order?.reserveDetails, pageProps?.order?.status]);

  useEffect(() => {
    addVehicleData({ modelId: selectedModelId, variantId: selectedVariantId });
  }, [selectedModelId, selectedVariantId]);

  useEffect(() => {
    if (selectedModelId && vehicleData && selectedVariantId) {
      const currentVariantData: IModelPackages[] = getCurrentVariantData(
        selectedVariantId,
        vehicleData?.[0],
      );
      setSelectablePrices(fetchOriginalPriceOfSelectables(currentVariantData));
    }
  }, [selectedModelId, selectedVariantId, vehicleData]);

  /* Side-effect called when clicked on the Videre button  */
  useEffect(() => {
    (async () => {
      if (props.isCalled) {
        if (isStockSaleOrder) {
          return moveToAccessoryPage();
        }
        const selectedData = {
          selectedModelId,
          selectedVariantId,
          selectedColorId,
          selectedInteriorId,
          selectedRims,
          selectedEquipmentIds,
          selectedListverk,
          selectedTaklakk,
        };
        const isOfferChanged = isOfferChange(pageProps, selectedData);

        if (isOfferChanged) {
          if (!isOfferValidationPassed(selectedData)) {
            setNotifications([
              <ErrorNotification
                key="offerCreationError"
                errorCode={ValidationErrorCode.COLOR_OR_INTERIOR_NOT_SELECTED}
                severityLevel={MessagebarSeverityLevel.ERROR}
                onClose={() => setNotifications([])}
              />,
            ]);
          } else {
            if (!!props.orderId && selectedModelId && selectedVariantId) {
              try {
                props?.setPickerLoading(true);
                const { vehicleConfig } = isVehicleChanged();
                const equipmentDataFromConfig = prepareEquipmentFromVehicleConfig(
                  vehicleConfig,
                  selectedEquipmentIds,
                  selectedRims,
                  selectedListverk,
                  selectedTaklakk,
                );
                const equipmentsData = prepareEquipments(
                  equipmentDataFromConfig?.selectedSelectable,
                  equipmentDataFromConfig?.selectedRim,
                  equipmentDataFromConfig?.selectedListverk,
                  equipmentDataFromConfig?.selectedTaklakk,
                );
                const optionsData = prepareOptions(
                  pageProps?.order,
                  vehicleConfig,
                  selectedColorId,
                  selectedInteriorId,
                );

                const { options, equipments, priceConjunctionTable } = updatedPricingForSelectableAndOptions(
                  optionsData,
                  equipmentsData,
                  mappedEquipment,
                  vehicle?.model?.make || offerMake,
                );

                const orderVariables = props.getUpdateOrderVariables(vehicleConfig);

                // As everytime new order vehicle is created, we need to update the default tyre
                // in the article
                const [, tyreArticle] = prepareTyresDefaultInputDuringSelection(
                  get(orderVariables, 'input.orderReference.pimCode'),
                  vehicleList,
                );

                const discountArticle = prepareDiscountInput(
                  vehicleConfig?.pricing?.customerAdvantageExcludingVat,
                  get(orderVariables, 'input.orderReference.pimCode'),
                  vehicleList
                );

                const articlesFinalPayload = cloneDeep(articles ?? [])
                  ?.map((article) => {
                    if (article?.key === VehicleConfig.TYRE) {
                      return tyreArticle?.[0];
                    }
                    if (article?.key === VehicleConfig.DISCOUNT) {
                      return discountArticle?.[0] || article;
                    }
                    return article;
                  })
                  .filter((ele) => ele);

                if (tyreArticle?.[0] && !articlesFinalPayload?.find((article) => article?.key === VehicleConfig.TYRE)) {
                  articlesFinalPayload?.push(tyreArticle?.[0]);
                }

                if (
                  discountArticle?.[0] &&
                  !articlesFinalPayload?.find((article) => article?.key === VehicleConfig.DISCOUNT)
                ) {
                  articlesFinalPayload?.push(discountArticle?.[0]);
                }

                const vatCalculation = await calculateVatPriceFromVehicleList(
                  options,
                  equipments,
                  articlesFinalPayload,
                  [],
                  true,
                  false,
                  carType,
                );

                if (!vatCalculation) {
                  props?.setPickerLoading(false);
                  props.setIsCalled(false);
                  raiseVatError();
                  return;
                }

                let variables: OperationVariables = {
                  orderId: getAppendingNumericIdFromUrl(),
                  vatCalculation,
                };
                //Add logic here to pass manufactureId & finalArticles
                const isModelChange = isChangeVehicleModelOnly(selectedData);
                let nextArticles: INextArticles = null;
                if (offerMake === MAKE.Mercedes) {
                  const manufactureComponentId = getManufactureModelId(
                    vehicleData,
                    selectedModelId,
                    selectedVariantId,
                  );
                  nextArticles = {
                    manufactureComponentId: !showMbAPIPopup ? manufactureComponentId : null,
                    articles: articlesFinalPayload,
                  };
                }
                setShowMbAPIPopup(false);
                setMBAPIErrorCode(null);
                if (isModelChange) {
                  const responseData = await updateVehicleModelOnly(vehicleConfig, vatCalculation, nextArticles);
                  props?.setPickerLoading(false);
                  if (!responseData?.mbApiStatus && !showMbAPIPopup) {
                    setShowMbAPIPopup(true);
                    setMBAPIErrorCode(responseData?.httpCode);
                    props.setIsCalled(false);
                    return;
                  }
                  if (responseData?.isSuccess) moveToAccessoryPage();
                  else raiseOfferError();
                } else {
                  variables = {
                    ...orderVariables,
                    ...variables,
                    vehicles: [],
                    options,
                    priceConjunctionList: priceConjunctionTable,
                    nextArticles,
                  };

                  const responseData = await updateDataOnChangedVehicle(
                    variables,
                    updateOrderWithVehicleMutation,
                    updateOrderWithVehicleEquipmentMutation,
                    equipments,
                    chosenOffer,
                    pageProps
                  );
                  props?.setPickerLoading(false);
                  if (!responseData?.mbApiStatus && !showMbAPIPopup) {
                    setShowMbAPIPopup(true);
                    setMBAPIErrorCode(responseData?.httpCode);
                    props.setIsCalled(false);
                    return;
                  }
                  if(responseData?.isWarning) {
                    props.raiseNotification(
                      <ErrorNotification
                        key="deliveryCharge"
                        severityLevel={MessagebarSeverityLevel.WARNING}
                        errorCode={ValidationErrorCode.DELIVERY_CHARGE_WARNING}
                        onClose={() => props?.clearNotifications('deliveryCharge')}
                      />,
                    )
                  }
                  if (responseData?.isSuccess) moveToAccessoryPage();
                  else raiseOfferError();
                }
              } catch (err) {
                props?.setPickerLoading(false);
                raiseOfferError();
              }
            }
          }
          props.setIsCalled(false);
        } else moveToAccessoryPage();
      }
    })();
  }, [props.isCalled]);

  //Technically, while in first page, there should not be any accessory, so removing if any
  const filterAccessoryFromArticles = (existingArticles: INextArticle[], isOptimisticUpdate: boolean = false) => {
    let filteredArticles: INextArticle[] = null;
    if (existingArticles && existingArticles?.length > 0) {
      filteredArticles = existingArticles?.filter((article) =>
        isOptimisticUpdate ? article : article.articleType !== ArticleType.ACCESSORY,
      );
      return filteredArticles;
    }
    return existingArticles;
  };

  const updateVehicleModelOnly = async (vehicleConfig: IVehicleConfiguration, vatCalculation: IVatCalculation, nextArticles) => {
    const variables = props.getUpdateOrderVariables(vehicleConfig);
    variables.vatCalculation = vatCalculation;
    variables.nextArticles = nextArticles;
    variables.input = {
      ...variables?.input,
    };
    const data = await updateVehicleModel(variables, updateOrderMutation);
    if (data?.createExistingOrder?.errorCode === NextErrorCode.MB_CONFIG_API_FAILED) return { mbApiStatus: false, isSuccess: false, httpCode: data?.createExistingOrder?.httpCode }
    if (data?.createExistingOrder?.id) {
      if (!data?.createExistingOrder?.pimSnapshot?.deliveryCharge) {
        props.raiseNotification(
          <ErrorNotification
          key="deliveryCharge"
          severityLevel={MessagebarSeverityLevel.WARNING}
          errorCode={ValidationErrorCode.DELIVERY_CHARGE_WARNING}
          onClose={() => props?.clearNotifications('deliveryCharge')}
        />,
        )
      }
      return { isSuccess: true, mbApiStatus: true };
    }
    return { isSuccess: false, mbApiStatus: true, httpCode: data?.createExistingOrder?.httpCode };
  };

  const moveToAccessoryPage = () => {
    const route = Routes.getVehicleAccessoriesPage(props.orderId);
    const redirectUrl = get(props?.stepMenuData, 'route') ? props?.stepMenuData?.route : route;
    props.setIsCalled(false);
    navigate(redirectUrl);
  };

  const perfomInitialFilter = (data: IVehicleStock[], isBaseModelAvailable: boolean, sku = selectedModelSku) =>
    getListAfterInitialFilter(
      sku,
      offerMake,
      data,
      pageProps?.vehicleReservedStatus,
      pageProps?.validLocationIds,
      isBaseModelAvailable,
    );

  const updateAvailableVehicleListForKIA = (vehicleData: IVehicleConfiguration[] = []) => {
    if (invokeProvidedBrandSpecificCode(MAKE.Kia, { order: pageProps?.order })) {
      const data = getStockEquipmentLevelsFromEquipments([], vehicleData?.[0]);
      setDataFromFactoryEquipLevels(data);
      const selectedVehicleStock: IVehicleStock[] = filterVehicleStockForKia(data, group, vehicleStock);
      let validVehicleList = [];
      if (selectedVehicleStock?.length) {
        validVehicleList = mapIsDisableVehicles(selectedVehicleStock, pageProps?.validLocationIds, pageProps?.vehicleReservedStatus);
      }
      setAvailableVehicles(validVehicleList);
    }
  };

  const updateAvailableVehicleList = (key: string = vehicleKey, sku: string = selectedModelSku) => {
    if (!invokeProvidedBrandSpecificCode(MAKE.Kia, { order: pageProps?.order })) {
      const selectedVehicleStock: IVehicleStock[] = vehicleStock?.getStock(key);
      let validVehicleList = [];
      const { isBaseModelAvailable, baseModel } = getAvailableBaseModel(packageSkus, props.modelOptions);

      if (selectedVehicleStock?.length) {
        if (
          isBaseModelAvailable &&
          isBaseModel(baseModel, sku) &&
          selectedVariantId !== null &&
          selectedVariantId !== ''
        ) {
          validVehicleList = getVehiclesForBaseModel(
            packageSkus,
            offerMake,
            selectedVehicleStock,
            pageProps?.validLocationIds,
            pageProps?.vehicleReservedStatus,
            isBaseModelAvailable,
          );
        } else if (selectedModelId && (selectedVariantId === null || selectedVariantId === '')) {
          validVehicleList = selectedVehicleStock;
        } else {
          validVehicleList = perfomInitialFilter(selectedVehicleStock, isBaseModelAvailable, sku);
        }
        setAvailableVehicles(validVehicleList);
      } else {
        setAvailableVehicles([]);
      }
    }
  };

  const rootClasses = classNames({
    vehiclePicker: true,
    mainContainer: true,
  });
  const onModelChange = ({ id, name, price, vatCode, dealerPriceExclVat, code }: ICartDataProps) => {
    setIsChange(true);
    props?.setIsChangeVehicle(true);
    setSelectedModelId(id);
    setSelectedModelCode(code);
    setSelectedModelName(name);
    setSelectedVariantId(null);
    setSelectedVariantName('');
    resetCurrentChange();
    if (invokeProvidedBrandSpecificCode(MAKE.Kia, { order: pageProps?.order })) {
      setDataFromFactoryEquipLevels(null);
      setAllSkuVehicleCount(null);
    }
    setBasePrice([price || 0, 0]);
    if (id) {
      const article = filterAccessoryFromArticles(
        prepareArticles(VehicleConfig.MODEL, price, vatCode, ArticleType.VEHICLE, dealerPriceExclVat, false),
      );
      setArticles([article?.[0]]);
      const data = prepareVehicleConfig(VehicleConfig.MODEL, id, name, price, vatCode);
      if (props?.isVehicleRemoved) {
        checkAndUpdateCart(VehicleConfig.MODEL, cartData, setCartData, data, !code, 0, false, false, true);
      } else {
        checkAndUpdateCart(VehicleConfig.MODEL, cartData, setCartData, data, !code, 0, false, true);
      }
    } else {
      setArticles([]);
      setCartData({ ...cartData, data: [] })
    }
    props?.setIsVehicleRemoved(false);
    eventEmitter.dispatch(Events.SKU_RULES_PREPARED, false);
  };

  const [getVehicleDataFromPIM] = useLazyQuery(queryVehicleDataFromPIM, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'cache-and-network',
    errorPolicy: ERROR_POLICY,
    onCompleted: (data) => {
      setIsLoading(false);
    },
    onError: (error) => {
      setIsLoading(false);
    },
  });

  const onVariantChange = async ({
    id,
    name,
    price,
    isRecommended,
    sku,
    modelCode,
    vatCode,
    configType,
    dealerPriceExclVat,
    path
  }: ICartDataProps) => {
    setSelectedVariantSku(sku);
    setSelectedVariantName(name);
    setIsChange(true);
    props?.setIsChangeVehicle(true);
    setSelectedVariantId(id);
    setSelectedModelSku(sku);
    setSelectedModelCode(modelCode);
    setBasePrice([basePrice?.[0], price || 0]);

    if (path) {
      const isValidCall = sku != vehicleList?.[0]?.sku;
      let selectedVehicleData = vehicleList;
      if (isValidCall) {
        setIsLoading(true);
        const { data: QueryResult } = await getVehicleDataFromPIM({ variables: { input: { path } } });
        selectedVehicleData = QueryResult?.getVehicleDataFromPIM?.results || [];
      }
      if (selectedVehicleData?.[0]) {
        setVehicleList(selectedVehicleData);
        updateAvailableVehicleListForKIA(selectedVehicleData);

        const formatedVehicleData: IVehicleModel[] = getStructuredVehicleData(selectedVehicleData);
        formatedVehicleData?.forEach((model) => {
          model?.salesPackages?.forEach((variant: IModelPackages) => {
            variant.isRecommended = variant?.price === 0;
          });
        });
        setVehicleData(formatedVehicleData);

        let deliveryCharge = 0;
        let oneOffTaxAndWreckage = 0;
        let discount = 0;
        let pimCode = '';
        let oneOffTax = 0;
        let wreckage = 0;
        const co2taxPrice = isStockSaleOrder ? defaultTo(pageProps?.order?.pimSnapshot?.co2Tax, 0) : 0;
        const noxtaxPrice = isStockSaleOrder ? defaultTo(pageProps?.order?.pimSnapshot?.noxTax, 0) : 0;
        const currentModelData: IVehicleModel[] = getCurrentModelData(selectedModelId, props.vehicleModelData);
        const currentVariantData: IModelPackages[] = getCurrentVariantData(id, currentModelData?.[0]);

        currentVariantData?.forEach((variant) => {
          if (variant?.id === id) {
            discount = variant?.customerAdvantageExcludingVat ?? 0;
            deliveryCharge = variant?.deliveryCharge;
            oneOffTaxAndWreckage = variant?.oneOffTaxWeight;
            pimCode = variant?.sku ?? '';
            oneOffTax = variant?.pricing?.oneOffTaxWeight ?? 0;
            wreckage = variant?.pricing?.extraWreckage ?? 0;
          }
        });
        // /* Default Tyre and delivery charge added to the Cart and Vat  */
        const [deliveryChargeCart, deliveryChargeArticle] = prepareDeliveryChargeInput(deliveryCharge, vatCode);
        const discountArticle = prepareDiscountInput(discount, pimCode, selectedVehicleData, pageProps?.order);
        const [tyreCartData, tyreArticle] = prepareTyresDefaultInputDuringSelection(
          pimCode,
          selectedVehicleData,
          pageProps?.order,
        );
        let calculatedOneOffTax: number = null;
        let factoryEquipmentLevel: ICrystallizeMappedFactoryEquipmentLevels = null;
        if (invokeProvidedBrandSpecificCode(MAKE.Kia, { vehicleConfiguration: selectedVehicleData?.[0] })) {
          calculatedOneOffTax = getOneOffTaxFromEquipments(selectedVehicleData?.[0], []);
          factoryEquipmentLevel = getFactoryEquipmentLevelForFactoryOrder(selectedVehicleData?.[0], []);
          if (isNil(factoryEquipmentLevel)) {
            setIsFactoryOrderButtonDisabled(true);
          } else if (!isNil(calculatedOneOffTax) && !isNil(factoryEquipmentLevel)) {
            setIsFactoryOrderButtonDisabled(false);
          }
        }
        const oneOffTaxArticle: INextArticle[] = [{
          key: VehicleConfig.TAREWEIGHT,
          priceExclVat: calculatedOneOffTax || oneOffTax,
          vatCode: VatCode.NOT_APPLICABLE,
          articleType: ArticleType.TAREWEIGHT,
          dealerPriceExclVat: 0,
        }];
        setArticles([
          ...filterAccessoryFromArticles(
            prepareArticles(
              VehicleConfig.VARIANT,
              price,
              vatCode,
              ArticleType.PACKAGE,
              dealerPriceExclVat,
              isRecommended,
              articles,
              false,
              id,
            ),
          ),
          ...(deliveryChargeArticle as INextArticle[]),
          ...(discountArticle ?? []),
          ...(tyreArticle ?? []),
          ...(oneOffTaxArticle ?? []),
          ...[
            {
              key: VehicleConfig.SCRAPDEPOSIT,
              priceExclVat: wreckage,
              vatCode: VatCode.NOT_APPLICABLE,
              articleType: ArticleType.SCRAPDEPOSIT,
              dealerPriceExclVat: 0,
            },
          ],
          ...[
            {
              key: VehicleConfig.CO2TAX,
              priceExclVat: co2taxPrice,
              vatCode: VatCode.NOT_APPLICABLE,
              articleType: ArticleType.CO2TAX,
              dealerPriceExclVat: 0,
            },
          ],
          ...[
            {
              key: VehicleConfig.NOXTAX,
              priceExclVat: noxtaxPrice,
              vatCode: VatCode.NOT_APPLICABLE,
              articleType: ArticleType.NOXTAX,
              dealerPriceExclVat: 0,
            },
          ],
        ]);

        const equipment: IEquipmentIdAndSKU[] = getMappedEquipement(selectedModelId, id, formatedVehicleData);
        setMappedEquipment(equipment);
        const data = prepareVehicleConfig(VehicleConfig.VARIANT, id, name, price, vatCode, isRecommended);
        const variantCartData = checkAndUpdateCart(VehicleConfig.VARIANT, cartData, setCartData, data, !id, 0, id !== '');
        variantCartData.image = getCurrentVariantData(id, formatedVehicleData?.[0])?.[0]?.image;
        if (!isEmpty(variantCartData)) {
          variantCartData.prices.oneOffTaxAndWreckage = oneOffTaxAndWreckage;
          const cartDataInclDeliveryCharge = checkAndUpdateCart(
            VehicleConfig.DELIVERY_CHARGE,
            variantCartData,
            setCartData,
            deliveryChargeCart as ICartSummaryData,
            !id,
            0,
            true,
          );
          if (tyreCartData) {
            checkAndUpdateCart(VehicleConfig.TYRE, cartDataInclDeliveryCharge, setCartData, tyreCartData);
          } else {
            setCartData(cartDataInclDeliveryCharge);
          }
        }

        // Prepare list of preselected Equipment skus and update Event        
        const conjunctionEquipmentData = rulesPayloadMapper(equipment);
        let onLoadDisableSkusList = new Map();
        onLoadDisableSkusList = getDisableSkuListOnLoad(currentVariantData, cartData?.make, conjunctionEquipmentData);

        let preSelectedSKUList = new Map();
        preSelectedSKUList = preparePreSelectedSKUList(formatedVehicleData?.[0], onLoadDisableSkusList, conjunctionEquipmentData);
        console.log("preSelectedSKUList =>", cloneDeep(preSelectedSKUList))

        eventEmitter.dispatch(Events.PRESELECT_SKU, preSelectedSKUList);
        eventEmitter.dispatch(Events.ENABLE_PRESELECT_SKU, true);
      }
    } else {
      //Reset Cart with contain only Motor-variant entry
      const modelCartData = cartData?.data?.find(item => item?.key === VehicleConfig.MODEL);
      if (props?.isVehicleRemoved) {
        checkAndUpdateCart(VehicleConfig.MODEL, cartData, setCartData, modelCartData, false, 0, false, false, true);
      } else {
        checkAndUpdateCart(VehicleConfig.MODEL, cartData, setCartData, modelCartData, false, 0, false, true);
      }
      //Reset Articles for vat calculation
      const modelArticleData = articles?.find(item => item?.key === VehicleConfig.MODEL);
      setArticles([modelArticleData]);
      props?.setIsVehicleRemoved(false);
    }
    resetCurrentChange();
  };

  const resetCurrentChange = () => {
    setSelectedColorId(null);
    setSelectedInteriorId(null);
    setSelectedEquipmentIds([]);
    setSelectedRims(null);
    setSelectedListverk(null);
    setSelectedTaklakk(null);
    setSelectableRulePayload([]);
  };

  const handleSkuPayload = (code: string | IEquipmentTypes[], value: string | IEquipmentTypes[], isTilleg = false) => {
    if (isTilleg) {
      const newSkus = map(code as IEquipmentTypes[], 'sku');
      const currentSkus = map(value as IEquipmentTypes[], 'sku');
      const removeExistingSku = selectableRulePayload?.filter((sku) => !currentSkus?.includes(sku));
      if (newSkus?.length) setSelectableRulePayload(prev => isEnablePreSelection ? [...removeExistingSku, ...newSkus, ...prev] : [...removeExistingSku, ...newSkus]);
      else setSelectableRulePayload(removeExistingSku);
    } else {
      const removeExistingSku = selectableRulePayload?.filter((sku) => sku !== value);
      if (code && typeof code === 'string') setSelectableRulePayload(prev => isEnablePreSelection ? [...removeExistingSku, code, ...prev] : [...removeExistingSku, code]);
      else setSelectableRulePayload(removeExistingSku);
    }
  };

  const isDisabled = (sku: string) => {
    const isDisable = isDisableSKUonChange(sku) ? true : isEnableSKUonChange(sku) ? false : isDisableSKUonLoad(sku);
    return isDisable;
  };

  const updatePrices = () => {
    if (isChange && isValidBrandForSelectables(cartData?.make ?? '')) {
      const articleSkus = findSelectableSkus<INextArticle>(articles);
      const disableArticles = removeInvalidValuesFromArray(articleSkus?.map((sku) => (isDisabled(sku) ? sku : '')));

      const cartSkus = findSelectableSkus<ICartSummaryData>(cartData?.data);
      const disableCartSkus = removeInvalidValuesFromArray(cartSkus?.map((sku) => (isDisabled(sku) ? sku : '')));

      updateCartDataPrices(cartData, setCartData, disableCartSkus, priceUpdate, selectablePrices);
      updateArticlePrices(articles, setArticles, disableArticles, priceUpdate, selectablePrices);
    }
  };

  const onColorChange = ({
    code,
    name,
    price,
    vatCode,
    isRecommended,
    isOptimisticUpdate,
    dealerPriceExclVat,
  }: ICartDataProps) => {
    handleSkuPayload(code, selectedColorId, false);
    const newArticles = [
      ...filterAccessoryFromArticles(
        prepareArticles(
          VehicleConfig.COLOR,
          price,
          vatCode,
          ArticleType.EQUIPMENT,
          dealerPriceExclVat,
          isRecommended,
          articles,
          !code,
          code,
        ),
        isOptimisticUpdate,
      ),
    ];

    setArticles(prev => isEnablePreSelection ? removeDuplicateObjectFromArray<INextArticle>([...newArticles, ...prev], 'key') : newArticles);

    if (!isOptimisticUpdate) {
      setIsChange(true);
      props?.setIsChangeVehicle(true);
      raiseWarningNotification();
    }
    setSelectedColorId(code);
    setSelectedColorName(name);
    const data = prepareVehicleConfig(VehicleConfig.COLOR, code, name, price, vatCode, isRecommended);
    const updatedCartdata = checkAndUpdateCart(VehicleConfig.COLOR, cartData, setCartData, data, !code, 0, true);
    setCartData(prev => {
      let data = removeDuplicateObjectFromArray<ICartSummaryData>([...updatedCartdata?.data || [], ...prev?.data || []], 'key')
      return isEnablePreSelection ? { ...updatedCartdata, data } : updatedCartdata;
    })
  };

  const onInteriorChange = ({
    code,
    name,
    price,
    vatCode,
    isRecommended,
    isOptimisticUpdate,
    dealerPriceExclVat,
  }: ICartDataProps) => {
    handleSkuPayload(code, selectedInteriorId, false);
    const newArticles = [
      ...filterAccessoryFromArticles(
        prepareArticles(
          VehicleConfig.INTERIOR,
          price,
          vatCode,
          ArticleType.EQUIPMENT,
          dealerPriceExclVat,
          isRecommended,
          articles,
          !code,
          code,
        ),
        isOptimisticUpdate,
      ),
    ]
    setArticles(prev => isEnablePreSelection ? removeDuplicateObjectFromArray<INextArticle>([...newArticles, ...prev], 'key') : newArticles);
    if (!isOptimisticUpdate) {
      setIsChange(true);
      props?.setIsChangeVehicle(true);
      raiseWarningNotification();
    }
    setSelectedInteriorId(code);
    setSelectedInteriorName(name);
    const data = prepareVehicleConfig(VehicleConfig.INTERIOR, code, name, price, vatCode, isRecommended);

    const updatedCartdata = checkAndUpdateCart(VehicleConfig.INTERIOR, cartData, setCartData, data, !code, 0, true);

    setCartData(prev => {
      let data = removeDuplicateObjectFromArray<ICartSummaryData>([...updatedCartdata?.data || [], ...prev?.data || []], 'key')
      return isEnablePreSelection ? { ...updatedCartdata, data } : updatedCartdata;
    })
  };

  const onRimChange = ({ rim, price, vatCode, isOptimisticUpdate, dealerPriceExclVat, effectedIndicator }: IRimChangeProps) => {
    handleSkuPayload(rim?.sku, selectedRims?.sku, false);
    const convertingTableChanges = handleConvertingsTableChange(
      [...selectedEquipmentIds || [], selectedListverk, selectedTaklakk, rim],
      !rim,
      isOptimisticUpdate,
      effectedIndicator,
    );
    let oneOffTaxArticles: INextArticle[] = convertingTableChanges?.oneOffTaxArticles;
    const newArticles = [
      ...filterAccessoryFromArticles(
        prepareArticles(
          VehicleConfig.RIM,
          price,
          vatCode,
          ArticleType.EQUIPMENT,
          dealerPriceExclVat,
          false,
          oneOffTaxArticles || articles,
          !rim,
          rim?.sku,
        ),
        isOptimisticUpdate,
      ),
    ];
    setArticles(prev => isEnablePreSelection ? removeDuplicateObjectFromArray<INextArticle>([...newArticles, ...prev], 'key') : newArticles)

    if (!isOptimisticUpdate) {
      setIsChange(true);
      props?.setIsChangeVehicle(true);
      raiseWarningNotification();
    }
    setSelectedRims(rim);
    const data = prepareVehicleConfig(VehicleConfig.RIM, rim?.sku, rim?.name, price, vatCode, false);
    const updatedCartdata = checkAndUpdateCart(VehicleConfig.RIM, cartData, setCartData, data, !rim, 0, true);

    setCartData(prev => {
      let data = removeDuplicateObjectFromArray<ICartSummaryData>([...updatedCartdata?.data || [], ...prev?.data || []], 'key')
      return isEnablePreSelection ? { ...updatedCartdata, data } : updatedCartdata;
    })
  };

  const onListverkChange = ({
    equipment,
    price,
    vatCode,
    isOptimisticUpdate,
    dealerPriceExclVat,
    effectedIndicator,
  }: IListverkChangeProps) => {
    handleSkuPayload(equipment?.sku, selectedListverk?.sku, false);
    const convertingTableChanges = handleConvertingsTableChange(
      [...selectedEquipmentIds || [], selectedTaklakk, selectedRims, equipment],
      !equipment,
      isOptimisticUpdate,
      effectedIndicator,
    );
    let oneOffTaxArticles: INextArticle[] = convertingTableChanges?.oneOffTaxArticles;
    const newArticles = [
      ...filterAccessoryFromArticles(
        prepareArticles(
          VehicleEquipmentCategoryEnum.Listverk,
          price,
          vatCode,
          ArticleType.EQUIPMENT,
          dealerPriceExclVat,
          false,
          oneOffTaxArticles || articles,
          !equipment,
          equipment?.sku,
        ),
        isOptimisticUpdate,
      ),
    ];
    setArticles(prev => isEnablePreSelection ? removeDuplicateObjectFromArray<INextArticle>([...newArticles, ...prev], 'key') : newArticles)

    if (!isOptimisticUpdate) {
      setIsChange(true);
      props?.setIsChangeVehicle(true);
      raiseWarningNotification();
    }
    setSelectedListverk(equipment);
    const data = prepareVehicleConfig(
      VehicleEquipmentCategoryEnum.Listverk,
      equipment?.sku,
      equipment?.name,
      price,
      vatCode,
      false,
    );
    const updatedCartdata = checkAndUpdateCart(VehicleEquipmentCategoryEnum.Listverk, cartData, setCartData, data, !equipment, 0, true);

    setCartData(prev => {
      let data = removeDuplicateObjectFromArray<ICartSummaryData>([...updatedCartdata?.data || [], ...prev?.data || []], 'key')
      return isEnablePreSelection ? { ...updatedCartdata, data } : updatedCartdata;
    })
  };

  const onTaklakkChange = ({
    equipment,
    price,
    vatCode,
    isOptimisticUpdate,
    dealerPriceExclVat,
    effectedIndicator,
  }: IListverkChangeProps) => {
    handleSkuPayload(equipment?.sku, selectedTaklakk?.sku, false);
    const convertingTableChanges = handleConvertingsTableChange(
      [...selectedEquipmentIds || [], selectedTaklakk, selectedRims, equipment],
      !equipment,
      isOptimisticUpdate,
      effectedIndicator,
    );
    let oneOffTaxArticles: INextArticle[] = convertingTableChanges?.oneOffTaxArticles;
    const newArticles = [
      ...filterAccessoryFromArticles(
        prepareArticles(
          VehicleEquipmentCategoryEnum.Taklakk,
          price,
          vatCode,
          ArticleType.EQUIPMENT,
          dealerPriceExclVat,
          false,
          oneOffTaxArticles || articles,
          !equipment,
          equipment?.sku,
        ),
        isOptimisticUpdate,
      ),
    ];
    setArticles(prev => isEnablePreSelection ? removeDuplicateObjectFromArray<INextArticle>([...newArticles, ...prev], 'key') : newArticles);
    if (!isOptimisticUpdate) {
      setIsChange(true);
      props?.setIsChangeVehicle(true);
      raiseWarningNotification();
    }
    setSelectedTaklakk(equipment);
    const data = prepareVehicleConfig(
      VehicleEquipmentCategoryEnum.Taklakk,
      equipment?.sku,
      equipment?.name,
      price,
      vatCode,
      false,
    );
    const updatedCartdata = checkAndUpdateCart(VehicleEquipmentCategoryEnum.Taklakk, cartData, setCartData, data, !equipment, 0, true);
    setCartData(prev => {
      let data = removeDuplicateObjectFromArray<ICartSummaryData>([...updatedCartdata?.data || [], ...prev?.data || []], 'key')
      return isEnablePreSelection ? { ...updatedCartdata, data } : updatedCartdata;
    })
  };

  // console.log("DataFromFactoryEquipLevels =>", dataFromFactoryEquipLevels)
  const onSelectEquipmentChange = (
    equipments: IEquipmentTypes[],
    removeId?: string | null,
    isOptimisticUpdate?: boolean,
    configType?: VehicleConfig | VehicleEquipmentCategoryEnum,
    dealerPriceExclVat?: number,
    effectedSku?: string,
  ) => {
    handleSkuPayload(equipments, selectedEquipmentIds, true);
    if (!isOptimisticUpdate) {
      setIsChange(true);
      props?.setIsChangeVehicle(true);
    }
    setSelectedEquipmentIds(prev => isEnablePreSelection ? [...prev, ...equipments] : equipments);
    const cartCopy: ICartProps = cloneDeep(cartData);
    let newArticles: INextArticle[] = [];
    const convertingTableChanges = handleConvertingsTableChange(
      [...equipments || [], selectedListverk, selectedTaklakk, selectedRims],
      !isNil(removeId),
      isOptimisticUpdate,
      effectedSku,
    );
    let oneOffTaxArticles: INextArticle[] = convertingTableChanges?.oneOffTaxArticles;
    if (removeId) {
      checkAndUpdateCart(`${VehicleConfig.SELECTABLE_EQUIPMENT}_${removeId}`, cartCopy, setCartData);
      newArticles = [
        ...filterAccessoryFromArticles(
          prepareArticles(
            `${VehicleConfig.SELECTABLE_EQUIPMENT}_${removeId}`,
            0,
            '',
            ArticleType.EQUIPMENT,
            0,
            false,
            oneOffTaxArticles || articles,
            true,
          ),
          isOptimisticUpdate,
        ),
      ];
      setArticles(newArticles);
    } else {
      equipments?.forEach((data) => {
        const selectEquipment = prepareVehicleConfig(
          `${VehicleConfig.SELECTABLE_EQUIPMENT}_${data?.id || ''}`,
          data?.sku,
          data?.name,
          data?.priceExcludingVat,
          data?.vatCode,
        );

        const updatedCartdata = checkAndUpdateCart(
          `${VehicleConfig.SELECTABLE_EQUIPMENT}_${data?.id || ''}`,
          cartCopy,
          setCartData,
          selectEquipment,
          false,
          0,
          true
        );

        setCartData(prev => {
          let data = removeDuplicateObjectFromArray<ICartSummaryData>([...updatedCartdata?.data || [], ...prev?.data || []], 'key')
          return isEnablePreSelection ? { ...updatedCartdata, data } : updatedCartdata;
        })

        newArticles = [
          ...filterAccessoryFromArticles(
            prepareArticles(
              `${VehicleConfig.SELECTABLE_EQUIPMENT}_${data?.id || ''}`,
              data?.priceExcludingVat,
              data?.vatCode,
              ArticleType.EQUIPMENT,
              dealerPriceExclVat,
              false,
              oneOffTaxArticles || articles,
              false,
              data?.sku,
            ),
            isOptimisticUpdate,
          ),
        ];
        setArticles(prev => isEnablePreSelection ? removeDuplicateObjectFromArray<INextArticle>([...newArticles, ...prev], 'key') : newArticles);
      });
    }
  };

  const handleConvertingsTableChange = (
    equipments: IEquipmentTypes[],
    removeId?: boolean,
    isOptimisticUpdate?: boolean,
    effectedSku?: string,
  ) => {
    let oneOffTaxArticles: INextArticle[] = null;
    let kiaOneOffTax: number = null;
    let factoryEquipmentLevel: ICrystallizeMappedFactoryEquipmentLevels = null;
    if (invokeProvidedBrandSpecificCode(MAKE.Kia, { vehicleConfiguration: vehicleList?.[0] })) {
      kiaOneOffTax = getOneOffTaxFromEquipments(vehicleList?.[0], equipments);
      factoryEquipmentLevel = getFactoryEquipmentLevelForFactoryOrder(vehicleList?.[0], equipments);
      if (isNil(kiaOneOffTax) || isNil(factoryEquipmentLevel)) {
        if (!removeId && effectedSku && !isDisabled(effectedSku)) {
          raiseOneOffTaxUnavailableNotification();
        }
        setIsFactoryOrderButtonDisabled(true);
      } else {
        setIsFactoryOrderButtonDisabled(false);
      }
      oneOffTaxArticles = [
        ...filterAccessoryFromArticles(
          prepareArticles(
            VehicleConfig.TAREWEIGHT,
            kiaOneOffTax,
            VatCode.NOT_APPLICABLE,
            ArticleType.TAREWEIGHT,
            0,
            false,
            articles,
            false,
          ),
          isOptimisticUpdate,
        ),
      ]
      //set factory equipment levels for stock counting
      const data = getStockEquipmentLevelsFromEquipments(equipments?.length ? equipments : [], vehicleList?.[0]);
      setDataFromFactoryEquipLevels(data);
    }

    return {
      oneOffTaxArticles
    };
  };

  const raiseWarningNotification = () => {
    if (pageProps?.selectedSerialNo && !raiseWarnNotification) {
      setRaiseWarnNotification(true);
      setNotifications([
        <ErrorNotification
          key="SerialNumberGone"
          severityLevel={MessagebarSeverityLevel.WARNING}
          errorCode={ValidationErrorCode.SERIAL_NUMBER_VALIDATION}
          onClose={() => setNotifications([])}
        />,
      ]);
    }
  };

  const isVehicleChanged = (
    changedModelId: string = pageProps.vehicleModelId,
    changedVarintId: string = pageProps.vehicleVariantId,
  ): IVehicleChanged => {
    let isVehicleChange = false;
    if (selectedModelId !== changedModelId || selectedVariantId !== changedVarintId) isVehicleChange = true;
    let vehicleConfig: IVehicleConfiguration = vehicle;
    if (isVehicleChange) {
      vehicleConfig = vehicleList?.[0]
    }

    return { isVehicleChange, vehicleConfig };
  };

  const raiseNotification = () =>
    setNotifications([
      <NextErrorNotification
        key="addVehicleError"
        severityLevel={MessagebarSeverityLevel.ERROR}
        errorCode={NextErrorCode.VEHICLE_ADD_ERROR}
        onClose={() => setNotifications([])}
      />,
    ]);

  const raiseOfferError = () =>
    setNotifications([
      <ErrorNotification
        key="offerCreationError"
        errorCode={ValidationErrorCode.OFFER_CREATION_ERROR}
        severityLevel={MessagebarSeverityLevel.ERROR}
        onClose={() => setNotifications([])}
      />,
    ]);

  const raiseVatError = () =>
    setNotifications([
      <ErrorNotification
        key="vatError"
        errorCode={ValidationErrorCode.VAT_VALIDATION}
        severityLevel={MessagebarSeverityLevel.ERROR}
        onClose={() => setNotifications([])}
      />,
    ]);

  const raiseOneOffTaxUnavailableNotification = () => {
    return setNotifications([
      <ErrorNotification
        key="oneOffTaxError"
        errorCode={ValidationErrorCode.ONE_OFF_TAX_NOT_FOUND_FROM_CONVERTING_TABLE}
        severityLevel={MessagebarSeverityLevel.ERROR}
        onClose={() => setNotifications([])}
      />,
    ]);
  };

  const onFactoryOrder = async (prodDate: string) => {
    if (!selectedColorId || !selectedInteriorId) {
      return setNotifications([
        <ErrorNotification
          key="FactoryOrderWarning"
          severityLevel={MessagebarSeverityLevel.WARNING}
          errorCode={ValidationErrorCode.COLOR_INTERIOR_NOT_SELECTED}
          onClose={() => setNotifications([])}
        />,
      ]);
    }
    const { isVehicleChange, vehicleConfig } = isVehicleChanged();
    const { colorId, color, colorPrice, colorValidFrom, colorValidTo, colorName } = getVarnishData(
      vehicleConfig,
      selectedColorId,
    );
    const { interiorId, interiorCode, interiorPrice, interiorValidFrom, interiorValidTo, interiorName } =
      getInteriorData(vehicleConfig, selectedInteriorId);
    const updatedRim = getConditionalValue(selectedRims, { ...selectedRims, category: null }, selectedRims);
    const selectedEquipments = compact([
      ...defaultTo(selectedEquipmentIds, []),
      updatedRim,
      selectedListverk,
      selectedTaklakk,
    ]);
    const isValidColorDate = validateDateBetweenTwoDates(colorName, colorValidFrom, colorValidTo, prodDate);
    const isValidInteriorDate = validateDateBetweenTwoDates(interiorName, interiorValidFrom, interiorValidTo, prodDate);
    if (isValidColorDate?.isError) {
      return setNotifications([
        <CustomErrorNotification
          key="FactoryOrderWarning"
          severityLevel={MessagebarSeverityLevel.ERROR}
          title={isValidColorDate?.selectableName}
          errorCode={ValidationErrorCode.INVALID_TIME_VALUE}
          onClose={() => setNotifications([])}
        />,
      ]);
    }
    if (isValidInteriorDate?.isError) {
      return setNotifications([
        <CustomErrorNotification
          key="FactoryOrderWarning"
          severityLevel={MessagebarSeverityLevel.ERROR}
          title={isValidInteriorDate?.selectableName}
          errorCode={ValidationErrorCode.INVALID_TIME_VALUE}
          onClose={() => setNotifications([])}
        />,
      ]);
    }

    const invalidEquipmentDate = selectedEquipments.map((equipment) => {
      return validateDateBetweenTwoDates(equipment?.name, equipment?.validFrom, equipment?.validTo, prodDate);
    });

    let invalidItems: string[] = [];
    const combinedValidation = compact([isValidColorDate, isValidInteriorDate, ...defaultTo(invalidEquipmentDate, [])]);
    if (combinedValidation?.length) {
      invalidItems.push(...combinedValidation.filter((data) => data && !data.isValid).map((data) => data.reason));
    }

    if (invalidItems?.length) {
      return setNotifications([
        <ProdDateWarning
          key={'FactoryOrderWarning'}
          invalidItems={invalidItems}
          onClose={() => setNotifications([])}
        />,
      ]);
    }
    setIsLoading(true);
    const factoryOrderData: IVehicleFactoryItem = {
      colorId,
      color,
      colorPrice,
      interiorId,
      interiorCode,
      interiorPrice,
      expectedProductionDate: prodDate,
      totalPrice: Number(defaultTo(vehicleConfig?.price, 0)) + Number(colorPrice) + Number(interiorPrice),
    };
    const vehicles: IVehicleInput = getFactoryVehicleInput(factoryOrderData, vehicleConfig);
    const optionsData: IVehicleOptionInput = getFactoryOptionInputs(pageProps.order, factoryOrderData, vehicleConfig);
    if (isEmpty(optionsData)) {
      setNotifications([
        <ErrorNotification
          key={'AddVehicleWarning'}
          errorCode={ValidationErrorCode.ADD_VEHICLE_COLOR_VALIDATION}
          severityLevel={MessagebarSeverityLevel.ERROR}
          onClose={() => setNotifications([])}
        />,
      ]);
      setIsLoading(false);
      if (isFunction(props?.setVehicleConfigLoading)) props.setVehicleConfigLoading(false);
      return;
    }

    const equipmentDataFromConfig = prepareEquipmentFromVehicleConfig(
      vehicleConfig,
      selectedEquipmentIds,
      selectedRims,
      selectedListverk,
      selectedTaklakk,
    );
    const equipmentsData = prepareEquipments(
      equipmentDataFromConfig?.selectedSelectable,
      equipmentDataFromConfig?.selectedRim,
      equipmentDataFromConfig?.selectedListverk,
      equipmentDataFromConfig?.selectedTaklakk,
    );

    const { options, equipments, priceConjunctionTable } = updatedPricingForSelectableAndOptions(
      optionsData,
      equipmentsData,
      mappedEquipment,
      defaultTo(vehicle?.model?.make, offerMake),
    );

    try {
      props?.setPickerLoading(true);
      const vatCalculation = await calculateVatPriceFromVehicleList(
        options,
        equipments,
        articles,
        props.articles,
        false,
        isVehicleChange,
        carType,
      );

      if (!vatCalculation) {
        props?.setPickerLoading(false);
        setIsLoading(false);
        setIsChange(false);
        props?.setIsChangeVehicle(false);
        if (isFunction(props?.setVehicleConfigLoading)) props.setVehicleConfigLoading(false);
        return raiseVatError();
      }
      let nextArticles: INextArticles = null;
      const manufactureComponentId = getManufactureModelId(vehicleData, selectedModelId, selectedVariantId);
      nextArticles = {
        manufactureComponentId: !showMbAPIPopup ? manufactureComponentId : null,
        articles,
      };
      setShowMbAPIPopup(false);
      setMBAPIErrorCode(null);
      let variables: OperationVariables = {
        vehicles,
        options,
        priceConjunctionList: priceConjunctionTable,
        vatCalculation,
        nextArticles,
      };

      statusChecked({
        ...statusChecked(),
        isNoFactoryCode: false,
      });
      if (isVehicleChange) {
        const orderVariables = props.getUpdateOrderVariables(vehicleConfig);
        variables = { ...orderVariables, ...variables };
        if (isEquipmentUnAvailable(equipments)) {
          await updateOrderWithVehicle(variables);
        } else {
          const { data } = await updateOrderWithVehicleEquipmentMutation({
            variables: {
              ...defaultTo(variables, {}),
              equipments,
            },
          });
          const calculationId = data?.createExistingOrderWithVehicleAndVehicleEquipment?.offers?.[0]?.id;
          if (calculationId && (isOrderStockType || isOrderDemoType)) {
            await chosenOffer(calculationId);
          }
          setIsLoading(false);
          if (
            data?.createExistingOrderWithVehicleAndVehicleEquipment?.errorCode === NextErrorCode.MB_CONFIG_API_FAILED
          ) {
            setShowMbAPIPopup(true);
            setMBAPIErrorCode(data?.createExistingOrderWithVehicleAndVehicleEquipment?.httpCode);
            setIsFactoryOrderClicked(true);
            return;
          }
          if (!data?.createExistingOrderWithVehicleAndVehicleEquipment?.id) {
            raiseNotification();
          } else {
            setIsFactoryOrderClicked(false);
            if (!data?.createExistingOrderWithVehicleAndVehicleEquipment?.pimSnapshot?.deliveryCharge) {
              props.raiseNotification(
                <ErrorNotification
                key="deliveryCharge"
                severityLevel={MessagebarSeverityLevel.WARNING}
                errorCode={ValidationErrorCode.DELIVERY_CHARGE_WARNING}
                onClose={() => props?.clearNotifications('deliveryCharge')}
              />,
              )
            }
          }
        }
      } else {
        //Add accessories in article if offer have it.
        let nextArticles: INextArticle[] = cloneDeep(variables?.nextArticles?.articles);
        let orderArticles: INextArticle[] = cloneDeep(props.articles);
        let updatedArticles: INextArticle[] = updateArticlesWithOrderAccessoryArticles(orderArticles, nextArticles);
        let requestedNextArticles = {
          manufactureComponentId: variables?.nextArticles?.manufactureComponentId,
          articles: updatedArticles,
        };
        variables = { orderId: getAppendingNumericIdFromUrl(), ...variables, nextArticles: requestedNextArticles };
        await updateVehiclesToOrder(variables, equipments);
      }
    } catch (err) {
      setIsLoading(false);
      raiseNotification();
      setIsFactoryOrderClicked(false);
    } finally {
      props?.setPickerLoading(false);
    }
  };

  const isEquipmentUnAvailable = (equipments = []): boolean => {
    return !pageProps?.order?.equipments?.length && !equipments?.length;
  };

  const updateOrderWithVehicle = async (variables: OperationVariables): Promise<void> => {
    const { data } = await updateOrderWithVehicleMutation({ variables });
    const calculationId = data?.createExistingOrderWithVehicle?.offers?.[0]?.id;
    if (calculationId && (isOrderStockType || isOrderDemoType)) {
      await chosenOffer(calculationId);
    }
    setIsLoading(false);
    if (data?.createExistingOrderWithVehicle?.errorCode === NextErrorCode.MB_CONFIG_API_FAILED) {
      setShowMbAPIPopup(true);
      setMBAPIErrorCode(data?.createExistingOrderWithVehicle?.httpCode);
      setIsFactoryOrderClicked(true);
      return;
    }
    if (!data?.createExistingOrderWithVehicle?.id) {
      raiseNotification();
    } else {
      setIsFactoryOrderClicked(false);
      if (!data?.createExistingOrderWithVehicle?.pimSnapshot?.deliveryCharge) {
        props.raiseNotification(
          <ErrorNotification
          key="deliveryCharge"
          severityLevel={MessagebarSeverityLevel.WARNING}
          errorCode={ValidationErrorCode.DELIVERY_CHARGE_WARNING}
          onClose={() => props?.clearNotifications('deliveryCharge')}
        />,
        )
      }
    }
  };

  const updateVehiclesToOrder = async (variables: OperationVariables, equipments): Promise<void> => {
    if (isEquipmentUnAvailable(equipments)) {
      await addVehicleToOrder(variables);
    } else {
      await addVehicleAndVehicleEquipmentToOrder(variables, equipments);
    }
  };
  const addVehicleToOrder = async (variables: OperationVariables): Promise<void> => {
    const { data } = await addVehiclesToOrder({ variables });
    setIsLoading(false);
    if (data?.addVehiclesToOrder?.errorCode === NextErrorCode.MB_CONFIG_API_FAILED) {
      setShowMbAPIPopup(true);
      setMBAPIErrorCode(data?.addVehiclesToOrder?.httpCode);
      setIsFactoryOrderClicked(true);
      return;
    }
    if (!data?.addVehiclesToOrder?.id) {
      raiseNotification();
    } else {
      setIsFactoryOrderClicked(false);
      if (!data?.addVehiclesToOrder?.pimSnapshot?.deliveryCharge) {
        props.raiseNotification(
          <ErrorNotification
          key="deliveryCharge"
          severityLevel={MessagebarSeverityLevel.WARNING}
          errorCode={ValidationErrorCode.DELIVERY_CHARGE_WARNING}
          onClose={() => props?.clearNotifications('deliveryCharge')}
        />,
        )
      }
    }
  };

  const addVehicleAndVehicleEquipmentToOrder = async (variables: OperationVariables, equipments): Promise<void> => {
    const { data } = await addVehiclesAndVehicleEquipmentToOrder({
      variables: {
        ...defaultTo(variables, {}),
        equipments,
      },
    });
    setIsLoading(false);
    if (data?.addVehiclesAndVehicleEquipmentToOrder?.errorCode === NextErrorCode.MB_CONFIG_API_FAILED) {
      setShowMbAPIPopup(true);
      setMBAPIErrorCode(data?.addVehiclesAndVehicleEquipmentToOrder?.httpCode);
      setIsFactoryOrderClicked(true);
      return;
    }
    if (!data?.addVehiclesAndVehicleEquipmentToOrder?.id) {
      raiseNotification();
    } else {
      setIsFactoryOrderClicked(false);
      if (!data?.addVehiclesAndVehicleEquipmentToOrder?.pimSnapshot?.deliveryCharge) {
        props.raiseNotification(
          <ErrorNotification
          key="deliveryCharge"
          severityLevel={MessagebarSeverityLevel.WARNING}
          errorCode={ValidationErrorCode.DELIVERY_CHARGE_WARNING}
          onClose={() => props?.clearNotifications('deliveryCharge')}
        />,
        )
      }
    }
  };

  useEffect(() => {
    if (vehicleCountBySeller) {
      if (!vehicleLocation) {
        setSpecificSellerVehicles([]);
      } else {
        setSpecificSellerVehicles(vehicleCountBySeller[vehicleLocation]?.vehicles || []);
      }
    }
  }, [vehicleCountBySeller]);

  const setSelectedLocationVehicles = (id) => {
    let key: string;
    switch (id) {
      case 'own':
        key = 'own';
        break;
      case 'importer':
        key = 'port';
        break;
      case 'dealer':
        key = 'other';
        break;
      default:
        key = null;
    }
    setVehicleLocation(key);
    if (key) setSpecificSellerVehicles(vehicleCountBySeller[key]?.vehicles || []);
    else setSpecificSellerVehicles([]);
  };

  const loadingSpinnerState =
    props?.isLoading || props?.isPickerLoading || !pageProps?.order || isLoading || props?.isVehicleListDataLoaded;

  useEffect(() => {
    const { exactMatchVehicleList, nonExactMatchVehicleList } = getExactAndNonExactMatchVehicleList(
      selectedSkus,
      vehicleCountBySeller,
      selectedColorId,
      selectedInteriorId,
      offerMake,
      props.modelOptions,
    );
    setVehicleListForExactMatch(exactMatchVehicleList);
    setVehicleListForNonExactMatch(nonExactMatchVehicleList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSkus, vehicleCountBySeller]);

  useEffect(() => {
    const { exactMatchSpecificSeller, nonExactMatchSpecificSeller } = getExactAndNonExactMatchSpecificSeller(
      selectedSkus,
      vehicleCountBySeller,
      selectedColorId,
      selectedInteriorId,
      offerMake,
      props.modelOptions,
    );
    setSpecificSellerVehiclesForExactMatch(exactMatchSpecificSeller);
    setSpecificSellerVehiclesForNonExactMatch(nonExactMatchSpecificSeller);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificSellerVehicles]);

  // Make cart empty when remove the vehicle
  useEffect(() => {
    if (props?.isVehicleRemoved) {
      onModelChange({ id: null, name: null, price: null, vatCode: null, dealerPriceExclVat: null, code: 'false' });
    }
  }, [props?.isVehicleRemoved]);

  const enableFactoryOrder = () => {
    if (
      isOrderEditable(pageProps?.order, props?.configuration) ||
      isStockSaleOrder ||
      props?.reserveButtonStage === ReserveButtonStages.RESERVED
    ) {
      return false;
    }
    if (offerMake === MAKE.Mercedes) {
      return isFactoryOrderEnable && !props?.isUnavailablePimData && !vehicleListForExactMatch?.length;
    } else {
      return isFactoryOrderEnable && !props?.isUnavailablePimData;
    }
  };

  const getProdDateDisabled = () => {
    if (offerMake === MAKE.Mercedes) {
      return true;
    }
    return false;
  };

  const handleClose = () => {
    props.setIsShowDeleteModel(false);
  };

  useDisableScroll(loadingSpinnerState);

  const handleCarResvervationWarning = () => {
    hasReservationBlockingCancellation(pageProps?.order?.reserveDetails) &&
      pageProps?.order?.reserveDetails?.vehicleReserveStatus?.toString() ===
      VehicleReserveStatus[VehicleReserveStatus.RESERVED] &&
      pageProps?.order?.status?.toString() !== OrderStatus[OrderStatus.CONFIRMED] &&
      props.raiseNotification(
        <ErrorNotification
          key="reservedCar"
          severityLevel={MessagebarSeverityLevel.WARNING}
          errorCode={ValidationErrorCode.RESERVED_CAR_VALIDATION}
          onClose={() => props?.clearNotifications('reservedCar')}
        />,
      );
  };

  useEffect(() => {
    if (rulesTableData) {
      const selectSkus = rulesTableData?.ruleAddVaules?.map((value) => {
        return value?.sku;
      });

      const removeSkus = rulesTableData?.ruleRemoveVaules?.map((value) => {
        return value?.sku;
      });
      const currentVariantData: IModelPackages[] = getCurrentVariantData(
        selectedVariantId,
        vehicleData?.[0],
      );
      const updatedCartData = prepareCartDataFromRulesTable(
        rulesTableData,
        cloneDeep(cartData),
        mappedEquipment,
      );

      const updatedArticlesResponse = prepareArticlesFromRulesTable(
        rulesTableData,
        articles,
        mappedEquipment,
        vehicleList?.[0],
      );
      const {
        equipmentIds,
        rimIds,
        colorId,
        interiorId,
        listverkData,
        taklakkData,
      } = getSelectionDataFromRulesTable(
        currentVariantData?.[0],
        rulesTableData,
        {
          selectedColorId,
          selectedInteriorId,
          selectedEquipmentIds,
          selectedRims,
          selectedListverk,
          selectedTaklakk,
        },
      )

      const [rulesPayload,]: [string[], { [key: string]: string; }] = prepareRulesPayloadFromSelectedData({
        equipmentIds,
        rimIds,
        colorId,
        interiorId,
        listverkData,
        taklakkData,
      });

      //START APPLYING PRESELECTION
      const {
        equipmentIds: equipsWithDefault,
        rimIds: rimWithDefault,
        colorId: colorIdWithDefault,
        interiorId: interiorIdWithDefault,
        listverkData: listverkWithDefault,
        taklakkData: taklakWithDefault,
      } = reApplyPreSelectionOnSkus(currentVariantData?.[0], rulesPayload, onloadDisableSku, {
        equipmentIds,
        rimIds,
        colorId,
        interiorId,
        listverkData,
        taklakkData
      })

      const [rulesPayloadAfterPreselection, rulesPayloadAfterPreselectionWithTypes]: [string[], { [key: string]: string; }] = prepareRulesPayloadFromSelectedData({
        equipmentIds: equipsWithDefault,
        rimIds: rimWithDefault,
        colorId: colorIdWithDefault,
        interiorId: interiorIdWithDefault,
        listverkData: listverkWithDefault,
        taklakkData: taklakWithDefault,
      });
      const newSkus = difference(rulesPayloadAfterPreselection, rulesPayload);
      let updatedArticlesResponseAfterPreselection = cloneDeep(updatedArticlesResponse);
      let updatedCartDataAfterPreselection = cloneDeep(updatedCartData);

      if (newSkus?.length) {
        updatedCartDataAfterPreselection = updateCartDataForDefaultSkus(newSkus, rulesPayloadAfterPreselectionWithTypes, currentVariantData?.[0], cloneDeep(updatedCartData), priceUpdate)
        updatedArticlesResponseAfterPreselection = updateArticlesForDefaultSkus(
          newSkus,
          rulesPayloadAfterPreselectionWithTypes,
          cloneDeep(updatedArticlesResponse?.updatedArticles),
          priceUpdate,
          currentVariantData?.[0],
          vehicleList?.[0]
        )
      }

      //END APPLYING PRESELECTION......
      // console.log("rulesPayload_Before_Preselection =>", rulesPayload)
      // console.log("rulesPayload_After_Preselection =>", rulesPayloadAfterPreselection)

      if (updatedArticlesResponseAfterPreselection?.isOneOffTaxError) {
        raiseOneOffTaxUnavailableNotification();
        setIsFactoryOrderButtonDisabled(true);
      }

      //Update Final vales in States, Cart & Articles
      setSelectedColorId(colorIdWithDefault);
      setSelectedInteriorId(interiorIdWithDefault);
      setSelectedEquipmentIds(equipsWithDefault);
      setSelectedRims(rimWithDefault);
      setSelectedListverk(listverkWithDefault);
      setSelectedTaklakk(taklakWithDefault);
      setArticles(updatedArticlesResponseAfterPreselection?.updatedArticles);
      setSelectableRulePayload(rulesPayloadAfterPreselection);
      setCartData(updatedCartDataAfterPreselection);
    }
  }, [rulesTableData]);

  return (
    <VehiclePickerPageMainStyled>
      {loadingSpinnerState ? (
        <div className="mainLoadingSpinnerContainer">
          <LoadingSpinner isModal={true} />
        </div>
      ) : null}

      <Tabs
        selectedTab={currentActiveTab}
        defaultTabId={PAGE_TYPE.CONFIGURE}
        underline
        className="newNext"
        GetActiveTabId={(param: TabId) => {
          setCurrentActiveTab(param);
        }}>
        <Tabs.TabList>
          <Tabs.Tab
            disabled={loadingSpinnerState}
            tabId={PAGE_TYPE.CONFIGURE}
            onTabClick={() => handleCarResvervationWarning()}>
            Konfigurer og bestill
          </Tabs.Tab>
          <Tabs.Tab
            onTabClick={() => {
              props.setIsLoading(isEmpty(saleAllCarsFilters) && vehicleStock.sizeOfStock() === null);
              setIsSpecificSellerEnabled(false);
              handleCarResvervationWarning();
            }}
            disabled={
              loadingSpinnerState ||
              isOrderEditable(pageProps?.order, props?.configuration) ||
              (!isChange && props.isFactoryOrder)
            }
            tabId={PAGE_TYPE.STOCK}>
            Biler på lager
          </Tabs.Tab>
        </Tabs.TabList>
        <Tabs.TabPanel tabId={PAGE_TYPE.CONFIGURE}>
          <VehiclePickerPageStyled className={rootClasses}>
            <>
              <ResultGrid
                vehicleData={vehicleData}
                vehicleModelData={props?.vehicleModelData}
                customerType={pageProps?.order?.customer?.customerType?.toString()}
                selectedModelId={selectedModelId}
                selectedVariantName={selectedVariantName}
                selectedModelName={selectedModelName}
                onModelChange={onModelChange}
                selectedVariantId={selectedVariantId}
                onVariantChange={onVariantChange}
                selectedColorId={selectedColorId}
                onColorChange={onColorChange}
                onRimChange={onRimChange}
                selectedRims={selectedRims}
                onSelectEquipmentChange={onSelectEquipmentChange}
                selectedEquipmentIds={selectedEquipmentIds}
                selectedInteriorId={selectedInteriorId}
                onInteriorChange={onInteriorChange}
                cartData={cartData}
                setCartData={setCartData}
                disabled={disabled}
                allSkuVehicleCount={allSkuVehicleCount}
                selectablesRulesSkuPayload={selectableRulePayload}
                onListverkChange={onListverkChange}
                selectedListverk={selectedListverk}
                updatePrices={updatePrices}
                isChange={isChange}
                vehicleBrand={vehicle?.model?.make || offerMake}
                onTaklakkChange={onTaklakkChange}
                selectedTaklakk={selectedTaklakk}
                modelOptions={props.modelOptions}
                selectedModelCode={selectedModelCode}
                setRulesTableData={setRulesTableData}
              />
              <div className="footerMenu">
                <SalesWizardButtonMenu
                  orderId={props?.orderId}
                  order={pageProps?.order}
                  calculationId={props?.calculationId}
                  activeStep={props?.activeStep}
                  isUnavailablePimData={props?.isUnavailablePimData}
                  menuLoading={props?.menuLoading}
                  onClick={props?.onClick}
                  onClickSigning={props?.onClickSigning}
                  bottomButton={true}
                  isStockSaleOrder={isStockSaleOrder}
                  stepData={stepData?.[stepData?.length - 1]}
                  vehicleAvailabilityCode={props.vehicleAvailabilityCode}
                  isChange={isChange}
                  isFactoryOrder={props.isFactoryOrder}
                />
              </div>
              <Cart
                make={cartData?.make}
                modelSeries={cartData?.modelSeries}
                prices={finalPrice}
                image={cartData?.image}
                data={filterSelectedEquipmentInCart(cartData?.data, getSelectedEquipmentInCart(cartData?.data))}
                isFilterAllow={true}
                isShowFooter={isStockSaleOrder ? true : (invokeProvidedBrandSpecificCode(MAKE.Kia, { order: pageProps?.order }) ? !!selectedVariantId : !!selectedModelId)}
                vehicleCount={filteredVehicleList?.length}
                location={location}
                isFactoryOrderEnable={enableFactoryOrder()}
                isVehicleSelected={isChange ? false : props.isVehicleSelected}
                serialNo={pageProps?.selectedSerialNo}
                isFactoryOrder={props.isFactoryOrder}
                reserveButtonStage={props.reserveButtonStage}
                isSelectedVehicleUnavailable={
                  props.isSelectedVehicleUnavailable ||
                  props.isUnavailablePimData ||
                  !props.isVehicleValidForReserveOrSign
                }
                selectedLocation={props?.vehicleLocation}
                selectedStatus={props?.vehicleStatus}
                equipmentDetails={
                  getSelectedEquipmentInCart(cartData?.data)?.length > 0
                    ? getSelectedEquipmentInCart(cartData?.data)
                    : null
                }
                reservationInfoText={
                  !props.isVehicleValidForReserveOrSign
                    ? OtherDealerVehicleReserveOrSignValidationMsg(props.vehicleAvailabilityCode)
                    : ''
                }
                onOpenVehicleList={() => {
                  setCurrentActiveTab(PAGE_TYPE.STOCK);
                  window?.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                onSaveFactoryOrder={onFactoryOrder}
                onReserveVehicle={props.onReserveOrCancelOrder}
                expectedProductionDate={expectedProductionDate}
                showReserveButton={showReserveButton && !props.isUnavailablePimData}
                showChangeSpecificCarButton={showChangeSpecificCarButton}
                vehicleCountBySeller={vehicleCountBySeller}
                onSellerChangeByCount={setSelectedLocationVehicles}
                isSpecificSellerEnbaled={(value) => setIsSpecificSellerEnabled(value)}
                isLoading={props.isLoading || props?.isPickerLoading}
                isProdDateDisabled={getProdDateDisabled()}
                exactMatchCount={props.exactMatchCount}
                nonExactMatchCount={props.nonExactMatchCount}
                isElectricCar={carType === ApplicationCode.ELECTRIC}
                showMBApiWarning={pageProps?.order?.externalTaxCalculation === MBApiCalculationFlag.FETCH_FAILED}
                showAbortFactoryOrderAction={props.isShowDeleteButton}
                abortFactoryBorder={{
                  buttonIcon: IconType?.NewNextTrash,
                  buttonClass: 'red',
                  buttonLabel: pageProps.order?.vehicles?.[0]?.serialNo ? 'Fjern bil' : 'Avbryt fabrikkbestilling',
                  buttonAction: () => {
                    props.setIsShowDeleteModel(!props.isShowDeleteModel);
                  },
                }}
                isFactoryOrderButtonDisabled={isFactoryOrderButtonDisabled}
              />
            </>
            <ToastArea notifications={notifications}></ToastArea>
            {props.isShowDeleteModel && (
              <CustomAlertPopup
                isOpen={props.isShowDeleteModel}
                onCloseClick={handleClose}
                deleteOrderData={props.deleteOrderData}
                className="deleteAlert"></CustomAlertPopup>
            )}

            {showMbAPIPopup && (
              <MBConfigModal
                setShowMbAPIPopup={setShowMbAPIPopup}
                setIsFactoryOrderClicked={setIsFactoryOrderClicked}
                showMbAPIPopup={showMbAPIPopup}
                isFactoryOrderClicked={isFactoryOrderClicked}
                onFactoryOrder={onFactoryOrder}
                expectedProductionDate={expectedProductionDate}
                setIsCalled={props?.setIsCalled}
                mbAPIErrorCode={mbAPIErrorCode}
              />
            )}
          </VehiclePickerPageStyled>
        </Tabs.TabPanel>
        <Tabs.TabPanel tabId={PAGE_TYPE.STOCK}>
          <VehicleStockListDialogStyled>
            <VehicleStockListDialog
              isVehicleReserved={
                pageProps?.order?.reserveDetails?.vehicleReserveStatus?.toString() ===
                VehicleReserveStatus[VehicleReserveStatus.RESERVED]
              }
              filters={saleAllCarsFilters}
              dataFromFactoryEquipLevels={dataFromFactoryEquipLevels}
              vehicleStockData={props?.vehicleStockDataForSellAllCars}
              selectedModelCode={selectedModelCode}
              selectedModelName={selectedModelName}
              selectedColorId={selectedColorId}
              selectedInteriorId={selectedInteriorId}
              selectedColorName={selectedColorName}
              selectedInteriorName={selectedInteriorName}
              selectedRims={selectedRims}
              selectedListverk={selectedListverk}
              selectedEquipmentIds={selectedEquipmentIds}
              selectedSerialNo={pageProps?.selectedSerialNo}
              selectedVariantSku={selectedVariantSku}
              selectedVariantName={selectedVariantName}
              getUpdateOrderVariables={props.getUpdateOrderVariables}
              order={pageProps?.order}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              isShowDeleteModel={props.isShowDeleteModel}
              setIsShowDeleteModel={props.setIsShowDeleteModel}
              deleteOrderData={props.deleteOrderData}
              setSelectedModelName={setSelectedModelName}
              validLocationIds={pageProps.validLocationIds}
              vehicleReservedStatus={pageProps.vehicleReservedStatus}
              vehicleLocation={vehicleLocation}
              dealerIds={props.dealerIds}
              importerLocationIds={props.importerLocationIds}
              isSpecificSellerEnabled={isSpecificSellerEnabled}
              raiseNotification={props.raiseNotification}
              clearNotifications={props.clearNotifications}
              modelSeries={
                pageProps?.order?.lead?.subFamilySeries?.toUpperCase() ||
                getModelSeries(null, queryModelSeries, pageProps?.order)
              }
            />
          </VehicleStockListDialogStyled>
        </Tabs.TabPanel>
      </Tabs>
    </VehiclePickerPageMainStyled>
  );
};
