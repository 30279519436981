import React, { useEffect, useState } from 'react';
import { AlternativeLeasingContainer, CustomOptionContainer } from './AlternativeFinancing.styled';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { BosDropdown, IBosDropDownOptions } from '@next-components/bos-dropdown';
import { Icon, IconType } from '@next-components/bos-icon';
import { bosColor } from '@next-components/common-styles';
import { Button } from '@next-components/button';
import { FinancingType, IBosCustomerFields, IOrder } from 'next-common';
import defaultTo from 'lodash/defaultTo';

interface IAlternativeFinancing {
  leasingCompanies?: IBosCustomerFields[];
  loading?: boolean;
  isSaving?: boolean;
  order?: IOrder;
  onAlertModalToggle?(): void;
  setLeasingInput(value): void;
  onAddCalculation?(financingType: FinancingType, changes?: any): Promise<IOrder>;
  removeAlternativeLeasing?(id: string): Promise<IOrder>;
  setAddNew?(addNew?: boolean): void;
}

const getLeasingCompanyOptions = (leasingCompanies: IBosCustomerFields[], loading: boolean) => {
  if (loading) {
    return [{ label: 'Loading...', value: '' }];
  }
  return leasingCompanies?.map((organization) => {
    return {
      label: organization?.organizationName,
      value: organization?.organizationNo,
    };
  });
};

export const AlternativeFinancing = (props: IAlternativeFinancing) => {
  const order = defaultTo(props?.order, null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isChange, setIsChange] = useState(false);
  const alternativeLeasing = defaultTo(order?.alternativeLeasing, null);
  const leasingCompanyOptions = getLeasingCompanyOptions(props?.leasingCompanies, props?.loading);

  useEffect(() => {
    if (alternativeLeasing) {
      const option = { label: alternativeLeasing.organizationName, value: alternativeLeasing.organizationNo };
      setSelectedOption({ ...option, label: customOption(option) });
      setIsChange(alternativeLeasing?.organizationNo !== option?.value);
    } else {
      setSelectedOption(null);
    }
  }, [alternativeLeasing]);

  const customOption = (param: IBosDropDownOptions) => (
    <CustomOptionContainer>
      <span className="optionLabel">{defaultTo(param?.label, '')}</span>
      <span className="distanceLabel">{defaultTo(param?.value, '')}</span>
    </CustomOptionContainer>
  );

  const getSelectedLeasingCompany = () => {
    return props?.leasingCompanies?.find((company) => company?.organizationNo === selectedOption?.value);
  };

  const handleSave = () => {
    const allOffers = defaultTo(order?.offers, []);
    const leasingOffers = allOffers?.filter((offer) => offer?.financingType === FinancingType.LEASING);
    const leasingCompany = getSelectedLeasingCompany();
    props?.setLeasingInput({ financingType: FinancingType.ALTERNATIVELEASING, input: leasingCompany });
    if (leasingOffers?.length) {
      props?.onAlertModalToggle();
    } else {
      props?.onAddCalculation(FinancingType.ALTERNATIVELEASING, leasingCompany);
      props?.setAddNew(false)
    }
  };

  const handleChange = (param: IBosDropDownOptions) => {
    setIsChange(param?.value !== alternativeLeasing?.organizationNo);
    setSelectedOption(param);
  };

  return (
    <AlternativeLeasingContainer>
      <div className="alternativeLeasingContent">
        <h3 className="leasingTitle">Alternativt leasingselskap</h3>
        <Messagebar
          messageBoxInfo
          severityLevel={MessagebarSeverityLevel.INFORMATION}
          title={
            <>
              Her kan du velge leasing fra et alternativt selskap.
              <br />
              Valgt selskap blir da eier av bilen.
            </>
          }
          className="customMessageBar"
          alertIcon={IconType.CircleInfo}
        />
        <BosDropdown
          label="Velg leasingselskap"
          value={selectedOption}
          options={leasingCompanyOptions?.map((item) => ({ ...item, label: customOption(item) }))}
          placeholder={'Velg leasingselskap'}
          iconProps={{
            iconDropdownDown: IconType.NewNextChevronDown,
            iconDropdownUp: IconType.NewNextChevronUp,
            iconColor: bosColor.black,
          }}
          nextDropdown
          onChange={(param) => {
            handleChange(param);
          }}
          className="leasingOptions"
        />
        <div className="buttonWrapper">
          <Button
            isLoading={props?.isSaving}
            onClick={() => handleSave()}
            className={`leasingAction selectLeasing ${props?.isSaving ? 'loading-button' : ''}`}
            disabled={!selectedOption || !isChange}>
            Oppdater alternativ leasing
          </Button>
          <Button
            variant="secondary"
            isLoading={props?.isSaving}
            onClick={() => props?.removeAlternativeLeasing(order?.id)}
            className={`leasingAction removeLeasing red ${props?.isSaving ? 'loading-button' : ''}`}
            disabled={!alternativeLeasing}>
            <div className="iconWrapper">
              <Icon icon={IconType?.NewNextTrash} className="buttonIcon" />
            </div>
            Slett alternativ leasing
          </Button>
        </div>
      </div>
    </AlternativeLeasingContainer>
  );
};
