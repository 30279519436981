import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styles from './App.module.scss';
import StartPage from './pages/StartPage/StartPage';
import AppHeader from './layout/AppHeader/AppHeader';
import { PATHS, Routes as Path, useConfigurations } from '../common';
import Error404 from './pages/Error404/Error404';
import SalesOrderView from './sales/sales-order-view';
import Overview from './pages/Margins/Overview/Overview';
import Details from './pages/Margins/Details/Details';
import ForbiddenPage from './pages/ForbiddenPage/ForbiddenPage';
import { connectToAzureWebPubSub, disconnectSocket, getMarginUser } from '../utils';
import { LoadingSpinner } from '@next-components/loading-spinner';
import { primary } from '@next-components/common-styles';
import packageInfo from '../../package.json';
import ScrollToTop from './layout/AppHeader/ScrollToTop';
import ProtectedRoute from './ProtectedRoute';
import TestDynamicListMer from './pages/ForbiddenPage/TestDynamicListMer';
import { useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';
import { IConfiguration } from 'next-common';
import { IVehicleUpdate } from './sales/sales-wizard';

export const manageSocket = (group: string, vehicleUpdate: (update: IVehicleUpdate) => void): void => {
  if (!group) {
    disconnectSocket();
    return;
  }
  if (group && vehicleUpdate) {
    connectToAzureWebPubSub(group, (update: IVehicleUpdate) => vehicleUpdate(update));
  }
};

const App = () => {
  const { accounts } = useMsal();
  const configurations: IConfiguration = useConfigurations();
  const user: AccountInfo = accounts?.[0] ?? null;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMargin, setIsMargin] = useState<boolean>(false);
  const [isMarginAllowed, setIsMarginAllowed] = useState<boolean>(false);
  const [isAdminAllowed, setIsAdminAllowed] = useState<boolean>(false);
  
  useEffect(() => {
    getMarginUser(user?.username)
      .then((data) => {
        setIsAdminAllowed(data?.isAdminAllow);
        setIsMargin(data?.isMargin);
        setIsMarginAllowed(data?.isMarginAllow);
        setIsLoading(false);
      })
      // tslint:disable-next-line:no-console
      .catch((err) => console.error(err));
  }, [user]);

  useEffect(() => {
    const frontEndVersion = localStorage.getItem('version');
    if (frontEndVersion !== packageInfo.version) {
      localStorage.clear();
      localStorage.setItem('version', packageInfo.version);
    }
  }, []);

  useEffect(() => {
    return () => {
      disconnectSocket();
    };
  }, []);

  const MainRoutes = () => {
    if (isLoading) {
      return (
        <div className={styles.loadingSpinners}>
          <LoadingSpinner size={48} color={primary} />
        </div>
      );
    }
    return (
      <ScrollToTop>
        <Routes>
          <Route
            path={Path.getHomePage()}
            element={isMargin ? <Navigate to={Path.getMarginsPage()} /> : <StartPage isAdmin={isAdminAllowed} configuration={configurations}  />}
          />
          <Route path={PATHS.view} element={<SalesOrderView configuration={configurations} manageSocket={manageSocket} />} />
          <Route path={PATHS.salesWizardRoot} element={<ProtectedRoute configuration={configurations} manageSocket={manageSocket} />} />
          <Route path={Path.getMarginsPage()} element={isMarginAllowed ? <Overview /> : <ForbiddenPage />} />
          <Route path={Path.getDetailsPage()} element={<Details />} />
          <Route path={Path.getForbiddenPage()} element={<ForbiddenPage />} />
          <Route path={Path.getDynamicListMer()} element={<TestDynamicListMer />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </ScrollToTop>
    );
  };
  return (
    <div className={styles.App}>
      <AppHeader />
      <div className={styles.AppContent}>
        <MainRoutes />
      </div>
    </div>
  );
};

export default App;
