import { get } from 'lodash';
import filter from 'lodash/filter';
import { IEquipmentTypes, VehicleEquipmentCategoryEnum } from 'next-common';
import React, { useEffect, useState } from 'react';
import { useDisableSkus, useEnableSkus, usePreSelectionEquipment, useSkusPriceUpdate, useUnselectSkus } from '../../../../../common';
import { createSkuListFromValue, eventEmitter, Events, getDisableEnableSKUList, isDisabledSKU, PreSelectedKeyEnum, priceFormatter, rulesPayloadMapper, VehicleConfig } from '../../../../../utils';
import { IModelPackages } from '../../VehiclePickerPage';
import { CarConfigureGroupStyled } from '../ResultGrid.styled';
import { VehicleSelectableDescription } from './VehicleSelectableDescription';
import { Card } from '@next-components/card';
import { CardType } from '@next-components/common-types';
import { MessagebarSeverityLevel } from '@next-components/messagebar';
import { ICommonRulesProps } from '../ResultGrid';
export interface IVehicleSelectableEquipment extends ICommonRulesProps {
  data: IModelPackages;
  customerType?: string;
  selectedEquipmentIds: IEquipmentTypes[];
  onSelectEquipmentChange: (
    data: IEquipmentTypes[],
    removeId?: string | null,
    isOptimisticUpdate?: boolean,
    configType?: VehicleConfig | VehicleEquipmentCategoryEnum,
    dealerPriceExclVat?: number,
    effectedSku?: string,
  ) => void;
  disabled: boolean;
  equipmentCategory: VehicleEquipmentCategoryEnum;
  rulesPayload?: string;
  updatePrices: () => void;
  setShowRulesPopup?: (show: boolean) => void;
}

export const VehicleSelectableEquipment = (props: IVehicleSelectableEquipment) => {
  const { data, selectedEquipmentIds, onSelectEquipmentChange, disabled, equipmentCategory } = props;
  const { isDisableSKUonChange, isDisableSKUonLoad, disableSku, onloadDisableSku } = useDisableSkus();
  const { isEnableSKUonChange } = useEnableSkus();
  const { getUnselectSkuFlag } = useUnselectSkus();
  const { updateKeyStatus, isEnablePreSelection, isSKUPreselected, isPreSelectionDone } = usePreSelectionEquipment();
  const priceUpdate = useSkusPriceUpdate();
  const [isPrepared, setIsPrepared] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [activeDesc, setActiveDesc] = useState<IEquipmentTypes>(null);

  useEffect(() => {
    eventEmitter.subscribe(Events.SKU_RULES_PREPARED, setIsPrepared);
    return () => {
      eventEmitter.unsubscribe(Events.SKU_RULES_PREPARED);
    };
  }, []);

  const onSelectableInputChange = (id: string, equipment: IEquipmentTypes) => {
    if (typeof props.setCurrentlySelectedSku === "function" && typeof props.setCurrentSelectionDetails === "function") {
      if (!id) {
        props.setCurrentlySelectedSku(null);
        
        props.setCurrentSelectionDetails({
          deSelectedSku: equipment?.sku,
          selectedSku: null,
        })
      } else {
        props.setCurrentSelectionDetails({
          deSelectedSku: null,
          selectedSku: null,
        })
        props.setCurrentlySelectedSku(equipment?.sku);
      }
    }
    let codes = [...selectedEquipmentIds];
    if (!id) {
      codes = codes?.filter((selected) => selected?.id !== equipment?.id);
    } else {
      codes.push(equipment);
    }
    onSelectEquipmentChange(codes, !id ? equipment?.id : null, false, VehicleConfig?.SELECTABLE_EQUIPMENT, equipment?.retailPrice, equipment?.sku);
  };

  const getPrice = (equipment: IEquipmentTypes) => {
    return priceUpdate?.has(equipment?.sku) ? Number(priceUpdate?.get(equipment?.sku)) : equipment?.priceExcludingVat;
  };

  useEffect(() => {
    //Update prices in cart & vat calculation
    if (selectedEquipmentIds?.length) {
      props.updatePrices();
    }
  }, [selectedEquipmentIds, priceUpdate]);

  const priceAdditionCheckToSelectable = (equipment: IEquipmentTypes) => priceFormatter(getPrice(equipment), equipment);

  const isDisabled = (sku: string) => {
    const isDisable = isDisableSKUonChange(sku) ? true : isEnableSKUonChange(sku) ? false : isDisableSKUonLoad(sku);
    return isDisable;
  };

  const isDisableRuleApply = (equipment: IEquipmentTypes) => {
    const isDisable = isDisabled(equipment?.sku);
    const isUnselectedSku = getUnselectSkuFlag(equipment?.sku);
    const isSelected = selectedEquipmentIds?.find((selected) => selected?.id === equipment?.id);
    if ((isUnselectedSku) && isPrepared && isSelected) {
      let codes = [...selectedEquipmentIds];
      codes = codes?.filter((selected) => selected?.id !== equipment?.id);
      onSelectEquipmentChange(codes, equipment?.id, true, VehicleConfig?.SELECTABLE_EQUIPMENT, equipment?.retailPrice);
    }
    return isDisable;
  };

  const updatePreselection = (equipment: IEquipmentTypes) => {
    if (equipmentCategory === VehicleEquipmentCategoryEnum.Exterior && isSKUPreselected(equipment?.sku, PreSelectedKeyEnum.EXT_TILLEGG) && isEnablePreSelection && !isPreSelectionDone(PreSelectedKeyEnum.EXT_TILLEGG)) {
      onSelectableInputChange(equipment?.id, equipment)
      updateKeyStatus(PreSelectedKeyEnum.EXT_TILLEGG)
    }
    else if (equipmentCategory === VehicleEquipmentCategoryEnum.Interior && isSKUPreselected(equipment?.sku, PreSelectedKeyEnum.INT_TILLEGG) && isEnablePreSelection && !isPreSelectionDone(PreSelectedKeyEnum.INT_TILLEGG)) {
      onSelectableInputChange(equipment?.id, equipment)
      updateKeyStatus(PreSelectedKeyEnum.INT_TILLEGG);
    }
    return false;
  }

  const isDiableSKU = (sku: string) => {
    const equipmentsData = data?.mappedEquipment;
    const enrichedEquipment = rulesPayloadMapper(equipmentsData);
    const { disableSkusMappedList, enableSkusMappedList } = getDisableEnableSKUList(
      props.rulesPayload,
      enrichedEquipment,
    );
    return isDisabledSKU(sku, disableSkusMappedList, enableSkusMappedList, onloadDisableSku);
  }

  useEffect(() => {
    selectedEquipmentIds?.forEach((selectedEquipment) => {
      const isDisable = isDiableSKU(selectedEquipment?.sku);
      if (!isDisable && isPrepared && selectedEquipment) {
        onSelectEquipmentChange(selectedEquipmentIds, null, true, VehicleConfig?.SELECTABLE_EQUIPMENT, selectedEquipment?.retailPrice);
      }
    });
  }, [selectedEquipmentIds]);

  const isDataAvailable = (equipment: IEquipmentTypes) => {
    return get(equipment, 'description');
  };

  const setCurrentData = (equipment: IEquipmentTypes) => {
    setActiveDesc(equipment);
    setActive(!active);
  };

  const getSkuNote = (sku: string) => {
    return createSkuListFromValue(disableSku, onloadDisableSku, sku);
  }

  const equipmentFilter = filter(data?.selectableEquipment ?? [], ['category', equipmentCategory]);

  return (
    <>
      <span className="label">Tillegg</span>
      {equipmentFilter?.length === 0 ? (
        <p> Ingen Tillegg tilgjengelig</p>
      ) : (
        <CarConfigureGroupStyled className="vehicleSelectableEquipment">
          {data?.selectableEquipment?.map?.((equipment) => {
            const isSelected = selectedEquipmentIds?.find((selected) => selected?.id === equipment?.id);
            const customPriceTag =
              getPrice(equipment) > 0
                ? `+ ${priceAdditionCheckToSelectable(equipment) || ''}`
                : priceAdditionCheckToSelectable(equipment);
            updatePreselection(equipment);
            return equipmentCategory === equipment?.category ? (
              <Card
                key={equipment?.id}
                id={equipment?.id}
                title={equipment?.name}
                customPriceTag={customPriceTag}
                type={CardType.BosCard}
                selected={Boolean(isSelected)}
                isDisabled={disabled}
                alertMessage={{
                  typeOfAlert: MessagebarSeverityLevel.WARNING,
                  showIcon: false,
                }}
                displayTag
                disableTag={!isDataAvailable(equipment)}
                tagValue={equipment?.sku}
                onTagClick={() => setCurrentData(equipment)}
                updateSelected={(id) => {
                  onSelectableInputChange(id, equipment);
                }}
              />
            ) : null;
          })}
          <VehicleSelectableDescription data={activeDesc} active={active} onClose={() => setActive(!active)} />
        </CarConfigureGroupStyled>
      )}
    </>
  );
};
